<template>
    <div id="right-bar">
        <div class="row-nav">
            <i :class="icon" @click="navControl"></i>
            <el-dropdown>
            <span class="el-dropdown-link">
                    欢迎您，{{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>个人中心</el-dropdown-item>
                    <el-dropdown-item>修改密码</el-dropdown-item>
                    <el-dropdown-item divided @click.native="logout">登出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div id="menu">
            <el-tag
                :key="tag"
                v-for="tag in dynamicTags"
                closable
                effect="plain"
                type="info"
                @click="choice_change(tag)"
                :class="{ 'selected-tag': tag === select_tag}"
                :disable-transitions="true"
                @close="handleClose(tag)">
                {{ tag }}
            </el-tag>
        </div>
    </div>
</template>

<script>
import {bus} from '../main'

export default {
    data() {
        return {
            dynamicTags: this.$global.choice_list.map(item => item[0]),
            icon: 'el-icon-s-fold',
            avatar: this.$cookies.get('avatar'),
            username: this.$cookies.get('username'),
            select_tag: this.$global.choice_list[this.$global.choice][0]
        }
    },
    mounted() {
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
        bus.$on('choiceChanged', (newValue) => {
            this.select_tag = this.$global.choice_list[newValue][0];
            if (newValue !== -1 && this.$global.choice_list[newValue][1] !== this.$route.path) {
                this.$router.push(this.$global.choice_list[newValue][1]);
            }
        });
        bus.$on('choiceListChanged', (newValue) => {
            this.dynamicTags = newValue.map(item => item[0]);
        });
    },
    methods: {

        handleClose(tag) {
            if (this.dynamicTags.indexOf(tag) === this.$global.choice) {
            this.$global.choice_list.splice(this.$global.choice, 1);
            this.$global.choice -= 1
            } else {
                this.$global.choice_list.splice(this.dynamicTags.indexOf(tag), 1);
            }
            bus.$emit('choiceListChanged', this.$global.choice_list)
            bus.$emit('choiceChanged', this.$global.choice);

        },
        navControl() {
            this.$global.isCollapse = !this.$global.isCollapse
            bus.$emit('isCollapseChanged', this.$global.isCollapse)
            if (this.icon === 'el-icon-s-unfold') {
                this.icon = 'el-icon-s-fold'
            } else {
                this.icon = 'el-icon-s-unfold'
            }
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.dynamicTags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        logout() {
            this.$cookies.keys().forEach(val => {
                this.$cookies.remove(val)
            })
            this.$router.replace('/login')


        },
        handleIsCookiesChanged(newValue) {
            this.username = this.$cookies.get('username')
        },
        choice_change(tag) {
            const index = this.$global.choice_list.findIndex(item => item.includes(tag));
            // const index = this.dynamicTags.indexOf(tag)
            this.$set(this.$global, 'choice', index);
            bus.$emit('choiceChanged', index);


        }
    }
}


</script>

<style scoped>
.el-dropdown {
    width: 400px;
    height: 40px;
    position: absolute;
    right: 60px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
}

.row-nav {
    border-bottom: 1px solid #ccc;
    height: 60px;
    display: block;
    position: relative;
    text-align: left;
}

.row-nav > i {
    font-size: 24px;
    margin: 20px 20px;

}

.el-avatar {
    margin-right: 40px;
    position: absolute;
    top: 10px;
    right: 100px;
}

#menu {
    border-bottom: 1px solid #ccc;
    box-shadow: #ccc 2px 2px 2px;
    text-align: left;
    padding-left: 0;
}

.el-tag {
    border-radius: 0;
    cursor: default;
    user-select: none;
}


.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.el-dropdown-link {
    display: inline-block;
    width: 400px;
    text-align: right;
}

.selected-tag {
    background-color: #409EFF !important; /* 设置选定标签的背景色 */
    color: #fff !important; /* 设置选定标签的文字颜色 */
}

/deep/ #menu .selected-tag .el-tag__close {
    color: white !important;
}
</style>