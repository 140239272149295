import { render, staticRenderFns } from "./ResignationView.vue?vue&type=template&id=99118136&scoped=true"
import script from "./ResignationView.vue?vue&type=script&lang=js"
export * from "./ResignationView.vue?vue&type=script&lang=js"
import style0 from "./ResignationView.vue?vue&type=style&index=0&id=99118136&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99118136",
  null
  
)

export default component.exports