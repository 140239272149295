<template>
    <div>
        <div class="seal-search">
            职工号
            <el-input
                placeholder="请输入职工姓名"
                v-model="teacher_id_search"
                size="small"
                clearable
            ></el-input>
            教职工姓名
            <el-input
                placeholder="请输入职工姓名"
                v-model="name_search"
                size="small"
                clearable
            ></el-input>
            在职状态
            <el-select v-model="status_search" clearable placeholder="请选择在职状态" size="small">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
            <el-button icon="el-icon-refresh" plain size="mini" @click="refresh">重置</el-button>
        </div>
        <div class="content-table">
            <div class="btn-group">
                <el-button type="primary" plain icon="el-icon-plus" @click="addARecord" size="mini">新增</el-button>
                <el-button type="success" plain icon="el-icon-edit" @click="change(multipleSelection[0])"
                           :disabled="change_disabled"
                           size="mini">修改
                </el-button>
                <a :href="fileUrl" class="download-btn">
                    <el-button type="warning" plain icon="el-icon-plus" size="mini">
                        下载Excel模版
                    </el-button>
                </a>
                <el-button type="primary" plain icon="el-icon-plus" @click="mutilADD" size="mini">Excel批量导入
                </el-button>
            </div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                :border=true
                tooltip-effect="dark"
                @current-change=""
                :header-cell-style="{background:'#f8f8f9'}"
                @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    label="序号"
                    width="70"
                    align="center"
                    prop="id"
                >
                </el-table-column>
                <el-table-column
                    label="职工号"
                    align="center"
                    prop="teacher_id"
                    min-width="120"
                >
                </el-table-column>
                <el-table-column
                    label="姓名"
                    align="center"
                    prop="name"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="性别"
                    align="center"
                    prop="get_gender"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.gender === 0" class="primary">女</div>
                        <div v-else class="senior">男</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="出生日期"
                    align="center"
                    prop="birthday"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="联系电话"
                    align="center"
                    prop="phone"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="邮箱"
                    align="center"
                    prop="email"
                    width="200"
                >
                </el-table-column>
                <el-table-column
                    label="在职状态"
                    align="center"
                    width="100"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.status === 0" class="primary">离职</div>
                        <div v-else-if="scope.row.status === 1" class="intermediate">休假</div>
                        <div v-else class="senior">在职</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="get_position"
                    label="职称"
                    align="center"
                    width="130"
                >
                </el-table-column>
                <el-table-column
                    prop="get_department"
                    label="所属院系"
                    align="center"
                    width="300"
                >
                </el-table-column>
                <el-table-column
                    label="操作"
                    align="center"
                    width="200"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <div class="operation-btn-group">
                            <el-button icon="el-icon-edit" type="text" @click="change(scope.row)">修改</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,30]"
                    :page-size.sync="page_size"
                    :current-page.sync="current_now"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <div class="upload" v-if="upload_show">
            <div class="upload-box">
                <div class="close">
                    <i class="el-icon-close" @click="mutilADD"></i>
                </div>
                <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :on-change="fileChange"
                    :auto-upload="false"
                    :file-list="file_list"
                    :limit="1">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传.xlsx文件</div>
                </el-upload>
                <el-button type="primary" size="small" @click="submitUpload">确认</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "@/main";

export default {
    data() {
        return {
            teacher_id_search: '',
            name_search: '',
            // 选择器数据
            options: [{
                value: 0,
                label: '离职'
            }, {
                value: 1,
                label: '休假'
            }, {
                value: 2,
                label: '在职'
            }],
            status_search: null,
            // 表格数据
            tableData: [],
            // 分页数据
            total: 0,
            current_now: 1,
            page_size: 10,
            // 按钮可用状态
            delete_disabled: true,
            change_disabled: true,
            // 选择项
            multipleSelection: [],
            // 调用编辑框
            create_change: null,
            title: null,
            row: {},
            base_url: 'personnel/teacher/?',
            fileUrl: '',
            upload_show: false,
            file_list: []
        }
    },
    created() {
        if (Number(this.$cookies.get('role')) === 1) {
            this.base_url = `${this.base_url}&department=${this.$cookies.get('department')}`
        }
        this.$axios({
            method: 'get',
            url: `${this.base_url}&size=${this.page_size}`
        }).then(response => {
            console.log(response)
            this.tableData = response.results.results
            this.total = response.results.count
        })
    },
    watch: {

        multipleSelection() {
            this.change_disabled = this.multipleSelection.length !== 1;
            this.delete_disabled = this.multipleSelection.length === 0;
        }
    },
    mounted() {
        this.downloadFile();
    },
    methods: {
        downloadFile() {
            // 假设在这里发送下载文件的请求，并将文件 URL 设置到 fileUrl 变量中
            this.fileUrl = 'http://127.0.0.1:8000/api/v1/download/teacher.xlsx';
        },
        handleSizeChange(val) {
            this.page_size = val
            this.current_now = 1
            this.$axios({
                method: 'GET',
                url: `${this.base_url}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        handleCurrentChange(val) {
            this.$axios({
                method: 'get',
                url: `${this.base_url}&page=${val}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                    this.total = response.results.count; // 更新总条目数
                })
                .catch(error => {

                })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        search() {
            let search = ''
            if (this.teacher_id_search) {
                search += `teacher_id=${this.teacher_id_search}&`
            }
            if (this.name_search) {
                search += `name=${this.name_search}&`
            }
            if (this.status_search !== null) {
                search += `status=${this.status_search}&`
            }

            this.$axios({
                method: 'get',
                url: `${this.base_url}&${search}size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        refresh() {
            this.name_search = ''
            this.status_search = null
            this.teacher_id_search = ''
            this.$axios({
                method: 'get',
                url: `${this.base_url}&size=${this.page_size}`
            }).then(response => {
                this.tableData = response.results.results
                this.total = response.results.count
            })
        },

        change(row) {
            this.title = "修改职工信息"
            this.create_change = 0
            this.row = row
            this.$emit('go')
        },
        addARecord() {
            this.title = "添加职工"
            this.create_change = 1
            this.$emit('go')
        },
        mutilADD() {
            this.file_list = []
            this.upload_show = !this.upload_show
        },
        fileChange(file, file_list) {
            this.file_list = file_list
        },
        submitUpload() {
            const fileType = this.file_list[0].name.substring(this.file_list[0].name.lastIndexOf('.'))
            if (fileType.toLowerCase() !== '.xlsx') {
                this.$message.error('文件必须为.xlsx类型')
                this.file_list = []
                return false
            }
            let formData = new FormData();
            this.file_list.forEach(item => {
                formData.append("file", item.raw);
            });

            this.$axios({
                method: 'post',
                url: 'personnel/teacher/file_update/',
                data: formData,
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(response => {
                    this.$message.success('上传成功！')
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&size=${this.page_size}`
                    }).then(response => {
                        this.tableData = response.results.results
                        this.total = response.results.count
                    })
                }
            ).catch(error => {
                    this.$message.error('上传失败，请稍后重试')
                }
            )
            this.upload_show = false
        }
    },


}
</script>

<style scoped>
.seal-search {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
}


.el-input {
    width: 200px;
    margin: 0 15px;
}

.el-select {
    margin: 0 15px;
}

.content-table {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 15px;
    padding: 20px;
    text-align: left;

}

.btn-group {
    margin-bottom: 15px;
}

.primary {
    font-size: 12px;
    background: #e7faf0;
    border: 1px solid #d0f5e0;
    color: #13ce66;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.status-close {
    font-size: 12px;
    background: #ffeded;
    border: 1px solid #ffdbdb;
    color: #ff4949;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.senior {
    font-size: 12px;
    background: #e8f4ff;
    border: 1px solid #d1e9ff;
    color: #1890ff;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.intermediate {
    font-size: 12px;
    background: #fff8e6;
    border: 1px solid #fff1cc;
    color: #ffba00;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.pagination {
    text-align: right;
    padding-top: 20px;
}

.operation-btn-group .el-button {
    height: 20px;
    font-size: 12px;
    padding: 0;
}

.upload {
    width: 100vw;
    height: 100vh;
    background: rgba(200, 200, 200, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.upload-box {
    width: 400px;
    height: 350px;
    background: white;
    margin-top: calc(50vh - 175px);
    margin-left: calc(50vw - 200px);
    position: relative;

}

.upload-box > .close {

    font-size: 20px;
    line-height: 30px;
    width: 100%;
    text-align: right;
    height: 30px;
    margin-bottom: 30px;

}

.upload-box > .close > i {
    margin-right: 15px;
    margin-top: 10px;
    cursor: pointer;
}

.upload-box .el-button {
    position: absolute;
    bottom: 10px;
    right: 24px;
}

.download-btn {
    margin: 0 10px;
}

</style>