<template>
    <div class="box" :style="left">
        <Leave ref="class" @go="go"></Leave>
        <div class="editor" v-if="edit_show">
            <div class="edit-form">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                    <div class="input-group">
                        <span>开始时间</span>
                        <el-date-picker
                            v-model="start_date"
                            type="date"
                            size="small"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                        <span>结束时间</span>
                        <el-date-picker
                            v-model="end_date"
                            type="date"
                            size="small"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>

                        <span>请假天数</span>
                        <el-input
                            v-model="number"
                            type="input"
                            size="small"
                            placeholder="请输入请假天数"
                            class="width-20"
                        ></el-input>
                        <div class="remark-box">
                            <span class="remark">请假事由</span>
                            <el-input
                                class='el-input-textarea'
                                type="textarea"
                                placeholder="请输入内容"
                                v-model="textarea"
                                maxlength="60"
                                rows="6"
                                show-word-limit
                                resize="none"
                            >
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="btn-group">
                    <el-button type="primary" size="small" @click="submit">确定</el-button>
                    <el-button size="small" @click="close">取消</el-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Leave from "@/components/Leave.vue";
import {bus} from '../main'

export default {
    data() {
        return {
            left: {},
            // 编辑框
            edit_show: false,
            title: '',
            number: null,
            start_date: '',
            end_date: '',
            base_url: 'apply/leave/?',
            textarea:null
        }
    },
    components: {
        Leave
    },
    created() {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        if (Number(this.$cookies.get('role')) === 1) {
            this.base_url = `${this.base_url}&department=${this.$cookies.get('department')}`
        }else if (Number(this.$cookies.get('role')) === 5) {
            this.base_url = `${this.base_url}&guide=${this.$cookies.get('teacher')}`
        } else if (Number(this.$cookies.get('role')) === 4) {
            this.base_url = `${this.base_url}&head=${this.$cookies.get('teacher')}`
        }
        if (this.$global.isCollapse) {
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        } else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted(event, callback) {
        // 事件总线注册监听时间
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
    },
    methods: {
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
        go() {
            this.title = this.$refs.class.title
            if (this.$refs.class.create_change === 0) {
                this.number = this.$refs.class.row.days
                this.start_date = this.$refs.class.row.start_date
                this.end_date = this.$refs.class.row.end_date
                this.textarea = this.$refs.class.row.reason
            }
            this.edit_show = true
        },
        close() {
            this.textarea = ''
            this.number = ''
            this.start_date = ''
            this.end_date = ''
            this.edit_show = false
        },
        submit() {
            if (this.$refs.class.create_change === 1) {
                this.$axios({
                    method: 'post',
                    url: `apply/leave/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        reason:this.textarea,
                        days: this.number,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        status:0,
                        remark:"",
                        person:this.$cookies.get('teacher'),
                        role:this.$cookies.get('role')
                    },
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&page=${this.$refs.class.current_now}&size=${this.$refs.class.page_size}&applicant=${this.$cookies.get('teacher')}&role=${this.$cookies.get('role')}`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    })
                        .then(response => {
                            this.$refs.class.total = response.results.count
                            this.$refs.class.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    });
                })
            } else {
                this.$axios({
                    method: 'put',
                    url: `apply/leave/${this.$refs.class.row.id}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        reason:this.textarea,
                        days: this.number,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        status:0,
                        remark:this.$refs.class.row.remark,
                        person:this.$cookies.get('teacher'),
                        role:this.$cookies.get('role')
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&page=${this.$refs.class.current_now}&size=${this.$refs.class.page_size}&applicant=${this.$cookies.get('teacher')}&role=${this.$cookies.get('role')}`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.$refs.class.total = response.results.count
                            this.$refs.class.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    })
                })
            }
            this.close()
        }
    }
}
</script>

<style scoped>
.box::-webkit-scrollbar {
    width: 0;
}

.box {
    overflow: auto;
    height: calc(100vh - 110px);

}

.editor {
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(200, 200, 200, 0.4);
    position: fixed;
    top: 0;
    left: 0;
}

.edit-form {
    width: 400px;
    height: 380px;
    background: white;
    position: fixed;
    top: calc(50vh - 190px);
    left: calc(50vw - 200px);
    border-radius: 4px;
    padding: 15px;
}

.editor .edit-form span {
    font-weight: bold;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.input-group {
    margin: 20px 0;
    text-align: left;
    font-size: 14px;
}

.el-input, .el-input-textarea, .el-cascader, .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 300px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.el-select {
    width: 300px;
    margin-bottom: 15px;
}

span {
    display: inline-block;
    width: 60px;
    text-align: right;
    margin-right: 15px;
}

.remark-box {
    position: relative;
}

.remark {
    position: absolute;
    top: 0;
}

.el-input-textarea {
    position: relative;
    left: 75px;
}

.btn-group {
    margin-top: -20px;
    text-align: right;
    margin-right: 25px;
}
.title {
    width: 200px;
    text-align: center;
}
</style>