import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '@/views/LoginView.vue';
import HomeView from "@/views/HomeView.vue";
import PositionView from "@/views/PositionView.vue";
import DepartmentView from "@/views/DepartmentView.vue";
import TeacherView from "@/views/TeacherView.vue";
import ResignationView from "@/views/ResignationView.vue";
import MajorView from "@/views/MajorView.vue";
import NoticeView from "@/views/NoticeView.vue";
import ClassView from "@/views/ClassView.vue";
import CourseTypeView from "@/views/CourseTypeView.vue";
import CourseView from "@/views/CourseView.vue";
import StopCourseView from "@/views/StopCourseView.vue";
import StudentView from "@/views/StudentView.vue";
import GraduationView from "@/views/GraduationView.vue";
import ClassRoomView from "@/views/ClassRoomView.vue";
import CourseTableView from "@/views/CourseTableView.vue";
import ArrangeCourseView from "@/views/ArrangeCourseView.vue";
import EnterScoreView from "@/views/EnterScoreView.vue";
import SearchScoreView from "@/views/SearchScoreView.vue";
import EvaluationTeacherView from "@/views/EvaluationTeacherView.vue";
import EvaluationContentView from "@/views/EvaluationContentView.vue";
import WorkSpaceView from "@/views/WorkSpaceView.vue";
import LeaveView from "@/views/LeaveView.vue";
import ErrorView from "@/views/404.vue";
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    }, {
        path: '/home',
        name: 'home',
        component: HomeView
    },
    {
        path: '/position',
        name: 'position',
        component: PositionView
    },
    {
        path: '/department',
        name: 'department',
        component: DepartmentView
    },
    {
        path: '/teacher',
        name: 'teacher',
        component: TeacherView
    },
    {
        path: '/resignation',
        name: 'resignation',
        component: ResignationView
    },
    {
        path: '/major',
        name: 'major',
        component: MajorView
    },
    {
        path: '/notice',
        name: 'notice',
        component: NoticeView
    },
    {
        path: '/class',
        name: 'class',
        component: ClassView
    },
    {
        path: '/course_type',
        name: 'course_type',
        component: CourseTypeView
    },
    {
        path: '/course',
        name: 'course',
        component: CourseView
    },
    {
        path: '/stop_course',
        name: 'stop_course',
        component: StopCourseView
    },
    {
        path: '/student',
        name: 'student',
        component: StudentView
    },
    {
        path: '/graduation',
        name: 'graduation',
        component: GraduationView
    },
    {
        path: '/class_room',
        name: 'class_room',
        component: ClassRoomView
    },
    {
        path: '/course_table',
        name: 'course_table',
        component: CourseTableView
    },
    {
        path: '/arrange_course',
        name: 'arrange_course',
        component: ArrangeCourseView
    },
    {
        path: '/enter_score',
        name: 'enter_score',
        component: EnterScoreView
    },
    {
        path: '/search_score',
        name: 'search_score',
        component: SearchScoreView
    },
    {
        path: '/evaluation_teacher',
        name: 'evaluation_teacher',
        component: EvaluationTeacherView
    },
    {
        path: '/evaluation_content',
        name: 'evaluation_content',
        component: EvaluationContentView
    },
    {
        path: '/work_space',
        name: 'work_space',
        component: WorkSpaceView
    },
    {
        path: '/leave',
        name: 'leave',
        component: LeaveView
    },
    {
        path: '/*',
        name: '404',
        component: ErrorView
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
