<template>
    <div class="box" :style="left">
        <WorkSpace ref="workspace" @go="go"></WorkSpace>
        <div class="editor" v-if="edit_show">
            <div class="edit-form">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                    <div class="remark-box">
                        <el-input
                            class='el-input-textarea'
                            type="textarea"
                            placeholder="请输入审批意见"
                            v-model="textarea"
                            maxlength="60"
                            rows="6"
                            show-word-limit
                            resize="none"
                        >
                        </el-input>
                    </div>
                    <div class="btn-group">
                        <el-button type="primary" size="small" @click="submit">确定</el-button>
                        <el-button type="danger" size="small" @click="reject">驳回</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WorkSpace from "@/components/WorkSpace.vue";
    import {bus} from "@/main";
    export default {
        components:{
            WorkSpace,
        },
        data () {
            return {
                left: {},
                edit_show: false,
                title:'请假审批单',
                textarea:''
            }
        },
        created() {
            if (this.$global.isCollapse) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto',
                }
            }
        },
        mounted(event, callback) {
            // 事件总线注册监听时间
            bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
            bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
        },
        methods:{
            handleIsCollapseChanged(newValue) {
                if (newValue) {
                    this.left = {
                        position: "absolute",
                        left: '74px',
                        width: 'calc(100vw - 104px)',
                        top: '110px',
                        overflow: 'auto',
                    }
                } else {
                    this.left = {
                        position: "absolute",
                        left: '210px',
                        width: 'calc(100vw - 230px)',
                        top: '110px',
                        overflow: 'auto'
                    }
                }
            },
            go () {
                this.edit_show = true
            },
            close () {
                this.edit_show = false
            },
            submit () {
                if (!this.textarea) {
                    this.$message.error('请输入审批意见')
                    return
                }
                this.$axios.put(`apply/leave/${this.$refs.workspace.row.id}/approval/`,
                    {
                        id:this.$refs.workspace.row.id,
                        status:2,
                        remark:this.textarea
                    }).then(response => {
                        this.$axios.get(`apply/leave/?size=${this.$refs.workspace.page_size}&user=${this.$cookies.get('teacher')}&status=0`)
                            .then(res => {
                                this.$refs.workspace.tableData = res.results.results
                                this.$refs.workspace.total = res.results.count
                            })
                })
                this.close()
            },
            reject () {
                if (!this.textarea) {
                    this.$message.error('请输入审批意见')
                    return
                }
                this.$axios.put(`apply/leave/${this.$refs.workspace.row.id}/approval/`,
                    {
                        id:this.$refs.workspace.row.id,
                        status:1,
                        remark:this.textarea
                    }).then(response => {
                    this.$axios.get(`apply/leave/?size=${this.$refs.workspace.page_size}&user=${this.$cookies.get('teacher')}&status=0`)
                        .then(res => {
                            this.$refs.workspace.tableData = res.results.results
                            this.$refs.workspace.total = res.results.count
                        })
                })
                this.close()
            }
        }
    }
</script>

<style scoped>
    .box::-webkit-scrollbar {
        width: 0;
    }

    .box {
        overflow: auto;
        height: calc(100vh - 110px);

    }
    .editor {
        width: 100vw;
        height: 100vh;
        z-index: 1000 !important;
        background: rgba(200, 200, 200, 0.4);
        position: fixed;
        top: 0;
        left: 0;
    }

    .edit-form {
        width: 600px;
        height: 240px;
        background: white;
        position: fixed;
        top: calc(50vh - 120px);
        left: calc(50vw - 300px);
        border-radius: 4px;
        padding: 20px;
    }
    .editor .edit-form .title {
        font-size: 16px;
        width: 200px;

    }

    .editor .edit-form i {
        cursor: pointer;
        float: right;
        font-weight: bold;
    }
    .remark-box {
        margin-top: 30px;
    }
    .btn-group{
        margin-top: 20px;
        text-align: right;
    }

</style>