<template>
    <div class="box" :style="left">
        <Department ref="department" @go="go"></Department>
        <div class="editor" v-if="edit_show">
            <div class="edit-form">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                    <div class="input-group">
                        <span>院系编号</span>
                        <el-input
                            v-model="department_id"
                            type="input"
                            size="small"
                            placeholder="请输入院系编号"
                        >
                        </el-input>
                        <span>院系名称</span>
                        <el-input
                            v-model="department_name"
                            type="input"
                            size="small"
                            placeholder="请输入院系名称"
                        >
                        </el-input>
                        <span>院 长</span>
                        <el-cascader
                            v-model="choice_head"
                            placeholder="试试搜索：数学"
                            :options="teacher_list"
                            filterable
                            class="width-25"
                            size="small"
                        ></el-cascader>
                        <span>办公地点</span>
                        <el-input
                            type="input"
                            v-model="location"
                            size="small"
                            :controls="false"
                            placeholder="请输入办公地点"
                            class="no-spinner width-25"

                        ></el-input>
                        <span>联系电话</span>
                        <el-input
                            type="input"
                            v-model="phone"
                            size="small"
                            :controls="false"
                            placeholder="请输入院系联系电话"
                            class="no-spinner width-25"

                        ></el-input>
                        <span>院系邮箱</span>
                        <el-input
                            v-model="email"
                            size="small"
                            :controls="false"
                            placeholder="请输入院系邮箱"
                            class="no-spinner width-25"
                        ></el-input>

                        <div class="remark-box">
                            <span class="remark">院系简介</span>
                            <el-input
                                class='el-input-textarea'
                                type="textarea"
                                placeholder="请输入院系简介"
                                v-model="textarea"
                                maxlength="60"
                                rows="6"
                                show-word-limit
                                resize="none"
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="btn-group">
                        <el-button type="primary" size="small" @click="submit">确定</el-button>
                        <el-button size="small" @click="close">取消</el-button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Department from "@/components/Department.vue";
import {bus} from '../main'

export default {
    data() {
        return {
            left: {},
            // 编辑框
            edit_show: false,
            title: '添加印章',

            location: '',
            department_id: '',
            department_name: null,
            textarea: "",
            choice_head: null,
            teacher_list: [],
            phone: '',
            email: '',
        }
    },
    components: {
        Department
    },
    created() {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        if (this.$global.isCollapse) {
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        } else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted(event, callback) {
        // 事件总线注册监听时间
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
    },
    methods: {
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
        go() {
            this.title = this.$refs.department.title
            if (this.$refs.department.create_change === 0) {
                this.department_id = this.$refs.department.row.department_id
                this.department_name = this.$refs.department.row.name
                this.choice_head = [this.$refs.department.row.get_head.department, this.$refs.department.row.get_head.id]
                this.location = this.$refs.department.row.location
                this.textarea = this.$refs.department.row.description
                this.phone = this.$refs.department.row.phone
                this.email = this.$refs.department.row.email
            }
            this.teacher_list = []
            this.$axios.get('personnel/department/all/')
                .then(res => {
                    res.results.forEach(async res => { // 使用async关键字
                        let result = []; // 用于存储请求结果
                        await this.$axios({
                            method: 'get',
                            url: `personnel/teacher/all/?department=${res.id}`,
                            headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                        }).then(response => {
                            response.results.forEach(item => {
                                result.push({
                                    value: item.id,
                                    label: item.name
                                });
                            });
                        })
                        this.teacher_list.push({
                            value: res.id,
                            label: res.name,
                            children: result // 将请求结果作为children返回
                        });
                    });
                })
            this.edit_show = true
        },
        close() {
            this.department_id = ''
            this.department_name = ''
            this.choice_head = null
            this.location = ''
            this.textarea = ''
            this.phone = ''
            this.email = ''
            this.edit_show = false
        },
        submit() {
            if (this.$refs.department.create_change === 1) {
                this.$axios({
                    method: 'post',
                    url: 'personnel/department/',
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        department_id:this.department_id,
                        name:this.department_name,
                        head:this.choice_head[1],
                        location:this.location,
                        phone:this.phone,
                        email:this.email,
                        description:this.textarea
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `personnel/department/?page=${this.$refs.department.current_now}&size=${this.$refs.department.page_size}`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    })
                        .then(response => {
                            this.$refs.department.total = response.results.count
                            this.$refs.department.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    });
                })
            } else {
                this.$axios({
                    method: 'put',
                    url: `personnel/department/${this.$refs.department.row.id}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        department_id:this.department_id,
                        name:this.department_name,
                        head:this.choice_head[1],
                        location:this.location,
                        phone:this.phone,
                        email:this.email,
                        description:this.textarea
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `personnel/department/?page=${this.$refs.department.current_now}&size=${this.$refs.department.page_size}`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.$refs.department.total = response.results.count
                            this.$refs.department.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    })
                })
            }
            this.close()
        }
    }
}
</script>

<style scoped>
.box::-webkit-scrollbar {
    width: 0;
}

.box {
    overflow: auto;
    height: calc(100vh - 110px);

}

.editor {
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(200, 200, 200, 0.4);
    position: fixed;
    top: 0;
    left: 0;
}

.edit-form {
    width: 400px;
    height: 540px;
    background: white;
    position: fixed;
    top: calc(50vh - 270px);
    left: calc(50vw - 200px);
    border-radius: 4px;
    padding: 15px;
}

.editor .edit-form span {
    font-weight: bold;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.input-group {
    margin: 20px 0;
    text-align: left;
    font-size: 14px;
}

.el-input, .el-input-textarea, .el-cascader {
    width: 300px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.el-select {
    width: 300px;
    margin-bottom: 15px;
}

span {
    display: inline-block;
    width: 60px;
    text-align: right;
    margin-right: 15px;
}

.remark-box {
    position: relative;
}

.remark {
    position: absolute;
    top: 0;
}

.el-input-textarea {
    position: relative;
    left: 75px;
}

.btn-group {
    margin-top: -20px;
    text-align: right;
    margin-right: 25px;
}

</style>