import { render, staticRenderFns } from "./WorkSpace.vue?vue&type=template&id=5aec312d&scoped=true"
import script from "./WorkSpace.vue?vue&type=script&lang=js"
export * from "./WorkSpace.vue?vue&type=script&lang=js"
import style0 from "./WorkSpace.vue?vue&type=style&index=0&id=5aec312d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aec312d",
  null
  
)

export default component.exports