<template>
    <div>

        <div class="content-table">

            <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                :border=true
                tooltip-effect="dark"
                @current-change=""
                :header-cell-style="{background:'#f8f8f9'}"
                @selection-change="handleSelectionChange">

                <el-table-column
                    type="index"
                    label="序号"
                    width="70"
                    align="center"
                    :index="indexMethod"
                >
                </el-table-column>
                <el-table-column
                    label="课程编号"
                    align="center"
                    prop="get_course_id"
                    min-width="120"
                >
                </el-table-column>
                <el-table-column
                    label="课程名称"
                    align="center"
                    prop="get_course"
                    width="240"
                >
                </el-table-column>
                <el-table-column
                    label="任课教师"
                    align="center"
                    prop="get_teacher"
                    min-width="100"
                >
                </el-table-column>

                <el-table-column
                    label="操作"
                    align="center"
                    width="200"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <div class="operation-btn-group">
                            <el-button icon="el-icon-edit" type="text" @click="change(scope.row)">评价</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,30]"
                    :page-size.sync="page_size"
                    :current-page.sync="current_now"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "@/main";

export default {
    data() {
        return {
            // 表格数据
            tableData: [],
            // 分页数据
            total: 0,
            current_now: 1,
            page_size: 10,
            // 按钮可用状态
            delete_disabled: true,
            change_disabled: true,
            // 选择项
            multipleSelection: [],
            // 调用编辑框
            create_change: null,
            title: null,
            row: {},
            group_search: '',
            class_list: [],
            base_url: `course/course_table/?teacher=${this.$cookies.get("teacher")}`,
            semester: '',
            semester_list: [{
                label: '第一学期',
                value: 1
            }, {
                label: '第二学期',
                value: 2
            }],
            school_year: '',
            school_year_list: [],
            fileUrl: '',
            upload_show: false
        }
    },
    created() {
        this.$axios.get(`course/evaluate_data/?student=${this.$cookies.get('teacher')}`)
            .then(response => {
                this.tableData = response.results.results
            })
    },
    mounted() {
        this.fileUrl = `${this.$base_url}download/score.xlsx`
    },
    watch: {

        multipleSelection() {
            this.change_disabled = this.multipleSelection.length !== 1;
            this.delete_disabled = this.multipleSelection.length === 0;
        }
    },
    methods: {
        indexMethod(index) {
          return index + 1;
        },
        handleSizeChange(val) {
            this.page_size = val
            this.current_now = 1
            this.$axios({
                method: 'GET',
                url: `${this.base_url}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        handleCurrentChange(val) {
            this.$axios({
                method: 'get',
                url: `${this.base_url}&page=${val}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                    this.total = response.results.count; // 更新总条目数
                })
                .catch(error => {

                })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        search() {
            this.tableData = []
            let search = ''
            if (!this.school_year) {
                this.$message.error('请先选择学年')
                return false
            }
            if (!this.semester) {
                this.$message.error('请先选择学期')
                return false
            }
            if (this.group_search) {
                search += `&department_id=${this.group_search[0]}&school_year=${this.school_year}&semester=${this.semester}`
            } else {
                search += `&school_year=${this.school_year}&semester=${this.semester}`
            }

            this.$axios({
                method: 'get',
                url: `${this.base_url}&${search}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        refresh() {
            this.group_search = ''
            this.school_year = ''
            this.semester = ''
            this.tableData = []

        },


        change(row) {
            this.title = "评价"
            this.create_change = 0
            this.row = row
            let flag = true
            this.$axios.get(`evaluation/evaluation_teacher/?student${this.$cookies.get('teacher')}&course=${row.course}`)
                .then(response => {
                    if (response.results.length > 0) {
                        flag = false
                        this.$message.error('该课程您已评价，不可修改')
                    }
                    if (flag) {
                        this.$emit('go')
                    }
                })
        },
    },


}
</script>

<style scoped>
.seal-search {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
}


.el-input {
    width: 200px;
    margin: 0 15px;
}

.el-select {
    margin: 0 15px;
}

.content-table {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 15px;
    padding: 20px;
    text-align: left;

}

.btn-group {
    margin-bottom: 15px;
}

.primary {
    font-size: 12px;
    background: #e7faf0;
    border: 1px solid #d0f5e0;
    color: #13ce66;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.status-close {
    font-size: 12px;
    background: #ffeded;
    border: 1px solid #ffdbdb;
    color: #ff4949;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.senior {
    font-size: 12px;
    background: #e8f4ff;
    border: 1px solid #d1e9ff;
    color: #1890ff;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.intermediate {
    font-size: 12px;
    background: #fff8e6;
    border: 1px solid #fff1cc;
    color: #ffba00;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.pagination {
    text-align: right;
    padding-top: 20px;
}

.operation-btn-group .el-button {
    height: 20px;
    font-size: 12px;
    padding: 0;
}

.upload {
    width: 100vw;
    height: 100vh;
    background: rgba(200, 200, 200, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.upload-box {
    width: 400px;
    height: 350px;
    background: white;
    margin-top: calc(50vh - 175px);
    margin-left: calc(50vw - 200px);
    position: relative;

}

.upload-box > .close {

    font-size: 20px;
    line-height: 30px;
    width: 100%;
    text-align: right;
    height: 30px;
    margin-bottom: 30px;

}

.upload-box > .close > i {
    margin-right: 15px;
    margin-top: 10px;
    cursor: pointer;
}

.upload-box .el-button {
    position: absolute;
    bottom: 10px;
    right: 24px;
}

.download-btn {
    margin: 0 10px;
}

</style>