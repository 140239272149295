<template>
    <div>
        <div class="seal-search">
            <span v-if="Number($cookies.get('role')) !== 7">
            课程类型
            <el-cascader
                v-model="group"
                placeholder="试试搜索：数学"
                :options="class_list"
                filterable
                class="width-25"
                size="small"
                style="margin-right: 10px"
            ></el-cascader>
            </span>
            学年
            <el-select v-model="school_year" clearable placeholder="请选择" size="small">
                <el-option
                    v-for="item in school_year_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            学期
            <el-select v-model="semester" clearable placeholder="请选择" size="small">
                <el-option
                    v-for="item in semester_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>

            <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
            <el-button icon="el-icon-refresh" plain size="mini" @click="refresh">重置</el-button>
        </div>
        <div class="content-table">

            <el-table
                :data="scheduleData"
                style="width: 100%"
                border
                stripe
            >
                <el-table-column
                    prop="time"
                    label="时间"
                    width="200"
                    height
                    align="center"
                ></el-table-column>
                <el-table-column
                    align="center"
                    v-for="(day, index) in days"
                    :key="index"
                    :label="day"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row[dictionary[index + 1]] && scope.row[dictionary[index + 1]].length">
                            <div v-for="item in scope.row[dictionary[index + 1]]" style="margin-top: 15px">
                                <h3>课程名:{{ item.get_course }}</h3>
                                <h4>{{ item.get_teacher }} {{ item.start_week }} - {{ item.end_week }}周</h4>
                                <h4>{{ item.get_address }} {{ item.get_exam_form }} </h4>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {bus} from "@/main";

export default {
    data() {
        return {
            // 选择器数据
            // 表格数据
            tableData: [],
            // 分页数据
            total: 0,
            current_now: 1,
            page_size: 10,
            // 按钮可用状态
            delete_disabled: true,
            change_disabled: true,
            // 选择项
            multipleSelection: [],
            // 调用编辑框
            create_change: null,
            title: null,
            row: {},
            group: null,
            class_list: [],
            days: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
            scheduleData: [
                {
                    time: '第一节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第二节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第三节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第四节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第五节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第六节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
            ],
            dictionary: {
                1: 'Monday',
                2: 'Tuesday',
                3: 'Wednesday',
                4: 'Thursday',
                5: 'Friday',
                6: 'Saturday',
                7: 'Sunday'
            },
            base_url: `course/course_table/all/?`,
            semester: null,
            semester_list: [{
                label: '第一学期',
                value: 1
            }, {
                label: '第二学期',
                value: 2
            }],
            school_year:'',
            school_year_list:[]
        }
    },
    created() {
        this.$axios.get('personnel/department/all/?')
            .then(res => {
                res.results.forEach(async res => { // 使用async关键字
                    let result = []; // 用于存储请求结果
                    await this.$axios({
                        method: 'get',
                        url: `class/class/all/?department=${res.id}`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    }).then(response => {
                        response.results.forEach(item => {
                            result.push({
                                value: item.id,
                                label: item.name
                            });
                        });
                    })
                    this.class_list.push({
                        value: res.id,
                        label: res.name,
                        children: result // 将请求结果作为children返回
                    });
                });
            })
        this.$axios.get('basic_data/school_year/')
            .then(response => {
                response.results.forEach(item => {
                    this.school_year_list.push({
                        value:item.id,
                        label:item.name
                    })
                })
            })
    },
    watch: {

        multipleSelection() {
            this.change_disabled = this.multipleSelection.length !== 1;
            this.delete_disabled = this.multipleSelection.length === 0;
        }
    },
    methods: {
        handleSizeChange(val) {
            this.page_size = val
            this.current_now = 1
            this.$axios({
                method: 'GET',
                url: `basic_data/course_type/?size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        handleCurrentChange(val) {
            this.$axios({
                method: 'get',
                url: `basic_data/course_type/?page=${val}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                    this.total = response.results.count; // 更新总条目数
                })
                .catch(error => {

                })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        search() {
            let search = ''
            this.scheduleData = [
                {
                    time: '第一节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第二节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第三节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第四节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第五节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第六节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
            ]
            if (Number(this.$cookies.get('role')) !== 7) {
                if (this.group && this.semester) {
                    search += `&group=${this.group[1]}&semester=${this.semester}&school_year=${this.school_year}`
                } else {
                    this.$message.error('请选择班级，学年，学期')
                    return false
                }
            } else {
                if (this.semester) {
                    search += `&student=${this.$cookies.get('teacher')}&semester=${this.semester}&school_year=${this.school_year}`
                } else {
                    this.$message.error('请选择学年，学期')
                    return false
                }
            }

            this.$axios({
                method: 'get',
                url: this.base_url + search,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    response.results.forEach(item => {
                        this.scheduleData[item.teach_time - 1][this.dictionary[item.teach_week]].push(item)
                    })
                })
                .catch(error => {

                })
        },
        refresh() {
            this.scheduleData = [
                {
                    time: '第一节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第二节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第三节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第四节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第五节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第六节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
            ]
            this.group = null
            this.semester = null
            this.school_year = null
        },
    }

}
</script>

<style scoped>
.seal-search {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
}

.el-input {
    width: 200px;
    margin: 0 15px;
}

.el-select {
    margin: 0 15px;
}

.content-table {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 15px;
    padding: 20px;
    text-align: left;

}

.btn-group {
    margin-bottom: 15px;
}

.primary {
    font-size: 12px;
    background: #e7faf0;
    border: 1px solid #d0f5e0;
    color: #13ce66;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.status-close {
    font-size: 12px;
    background: #ffeded;
    border: 1px solid #ffdbdb;
    color: #ff4949;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.senior {
    font-size: 12px;
    background: #e8f4ff;
    border: 1px solid #d1e9ff;
    color: #1890ff;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.intermediate {
    font-size: 12px;
    background: #fff8e6;
    border: 1px solid #fff1cc;
    color: #ffba00;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.pagination {
    text-align: right;
    padding-top: 20px;
}

.operation-btn-group .el-button {
    height: 20px;
    font-size: 12px;
    padding: 0;
}
</style>