<template>
    <div class="box" :style="left">
        <Resignation ref="resignation" @go="go"></Resignation>
        <div class="editor" v-if="edit_show">
            <div class="edit-form">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                    <div class="input-group">
                        <span>职工号</span>
                        <el-input
                            v-model="teacher_id"
                            type="input"
                            size="small"
                            placeholder="请输入职工号"
                        >
                        </el-input>
                        <span>姓 名</span>
                        <el-input
                            v-model="resignation_name"
                            type="input"
                            size="small"
                            placeholder="请输入姓名"
                        >
                        </el-input>
                        <span>性 别</span>
                        <el-select v-model="gender" clearable placeholder="请选择性别" size="small">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <span>出生日期</span>
                        <el-date-picker
                            v-model="birthday"
                            type="date"
                            size="small"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                        <span>联系电话</span>
                        <el-input
                            v-model="phone"
                            size="small"
                            placeholder="请输入联系电话"
                            class="no-spinner width-25"

                        ></el-input>
                        <span>联系邮箱</span>
                        <el-input
                            v-model="email"
                            size="small"
                            placeholder="请输入联系邮箱"
                            class="no-spinner width-25"

                        ></el-input>
                        <span>办公地址</span>
                        <el-input
                            v-model="address"
                            size="small"
                            placeholder="请输入办公地址"
                            class="no-spinner width-25"

                        ></el-input>
                        <span>入职日期</span>
                        <el-date-picker
                            v-model="hire_date"
                            type="date"
                            size="small"
                            value-format="yyyy-MM-dd"
                            placeholder="选择入职日期">
                        </el-date-picker>
                        <span>在职状态</span>
                        <el-select v-model="hire_status" clearable placeholder="请选择在职状态" size="small" disabled>
                            <el-option
                                v-for="item in status_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <span>职称信息</span>
                        <el-select v-model="position" clearable placeholder="请选择职称信息" size="small">
                            <el-option
                                v-for="item in position_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <span>所属院系</span>
                        <el-select v-model="department" clearable placeholder="请选择所属院系" size="small">
                            <el-option
                                v-for="item in department_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="btn-group">
                        <el-button type="primary" size="small" @click="submit">确定</el-button>
                        <el-button size="small" @click="close">取消</el-button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Resignation from "@/components/Resignation.vue";
import {bus} from '../main'

export default {
    data() {
        return {
            left: {},
            // 编辑框
            edit_show: false,
            title: '添加印章',
            gender: '',
            options: [{
                value: 1,
                label: '男'
            }, {
                value: 0,
                label: '女'
            }],
            teacher_id: '',
            birthday: '',
            phone: '',
            email: '',
            address: '',
            resignation_name: null,
            hire_date: '',
            hire_status: '',
            status_list: [{
                value: 0,
                label: '离职'
            }, {
                value: 1,
                label: '休假'
            }, {
                value: 2,
                label: '在职'
            }],
            position: '',
            position_list: [],
            department: '',
            department_list: [],
            textarea: "",
            base_url:'personnel/teacher/?'
        }
    },
    components: {
        Resignation
    },
    created() {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        if (Number(this.$cookies.get('role')) === 1 ){
            this.base_url = `${this.base_url}&department=${this.$cookies.get('department')}`
        }
        if (this.$global.isCollapse) {
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        } else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted(event, callback) {
        // 事件总线注册监听时间
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
    },
    methods: {
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
        go() {
            this.title = this.$refs.resignation.title
            if (this.$refs.resignation.create_change === 0) {
                this.teacher_id = this.$refs.resignation.row.teacher_id
                this.resignation_name = this.$refs.resignation.row.name
                this.gender = this.$refs.resignation.row.gender
                this.birthday = this.$refs.resignation.row.birthday
                this.phone = this.$refs.resignation.row.phone
                this.email = this.$refs.resignation.row.email
                this.address = this.$refs.resignation.row.address
                this.hire_date = this.$refs.resignation.row.hire_date
                this.hire_status = this.$refs.resignation.row.status
                this.position = this.$refs.resignation.row.position
                this.department = this.$refs.resignation.row.department
            }
            this.department_list = []
            this.position_list = []
            this.$axios.get('basic_data/position/all/')
                .then(response => {
                    response.results.forEach(values => {
                        this.position_list.push({value: values.id, label: values.name})
                    })
                })
            this.$axios.get('personnel/department/all/')
                .then(response => {
                    response.results.forEach(values => {
                        this.department_list.push({value: values.id, label: values.name})
                    })
                })
            this.edit_show = true
        },
        close() {
            this.teacher_id = ''
            this.resignation_name = ''
            this.gender = null
            this.birthday = ''
            this.phone = ''
            this.email = ''
            this.address = ''
            this.hire_date = ''
            this.hire_status = ''
            this.position = ''
            this.department = ''
            this.edit_show = false
        },
        submit() {
            if (this.$refs.resignation.create_change === 1) {
                this.$axios({
                    method: 'post',
                    url: 'personnel/teacher/',
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        teacher_id: this.teacher_id,
                        name: this.resignation_name,
                        gender: this.gender,
                        birthday: this.birthday,
                        phone: this.phone,
                        email: this.email,
                        address: this.address,
                        hire_date: this.hire_date,
                        status: this.hire_status,
                        position: this.position,
                        department: this.department
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&page=${this.$refs.resignation.current_now}&size=${this.$refs.resignation.page_size}&status=0`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    })
                        .then(response => {
                            this.$refs.resignation.total = response.results.count
                            this.$refs.resignation.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    });
                })
            } else {
                this.$axios({
                    method: 'put',
                    url: `personnel/teacher/${this.$refs.resignation.row.id}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        teacher_id: this.teacher_id,
                        name: this.resignation_name,
                        gender: this.gender,
                        birthday: this.birthday,
                        phone: this.phone,
                        email: this.email,
                        address: this.address,
                        hire_date: this.hire_date,
                        status: this.hire_status,
                        position: this.position,
                        department: this.department
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&page=${this.$refs.resignation.current_now}&size=${this.$refs.resignation.page_size}&status=0`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.$refs.resignation.total = response.results.count
                            this.$refs.resignation.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    })
                })
            }
            this.close()
        }
    }
}
</script>

<style scoped>
.box::-webkit-scrollbar {
    width: 0;
}

.box {
    overflow: auto;
    height: calc(100vh - 110px);

}

.editor {
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(200, 200, 200, 0.4);
    position: fixed;
    top: 0;
    left: 0;
}

.edit-form {
    width: 400px;
    height: 620px;
    background: white;
    position: fixed;
    top: calc(50vh - 310px);
    left: calc(50vw - 200px);
    border-radius: 4px;
    padding: 15px;
}

.editor .edit-form span {
    font-weight: bold;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.input-group {
    margin: 20px 0;
    text-align: left;
    font-size: 14px;
}

.el-input, .el-input-textarea, .el-date-editor.el-input {
    width: 300px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.el-select {
    width: 300px;
    margin-bottom: 15px;
}

span {
    display: inline-block;
    width: 60px;
    text-align: right;
    margin-right: 15px;
}

.remark-box {
    position: relative;
}

.remark {
    position: absolute;
    top: 0;
}

.el-input-textarea {
    position: relative;
    left: 75px;
}

.btn-group {
    margin-top: -20px;
    text-align: right;
    margin-right: 25px;
}

</style>