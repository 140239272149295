<template>
    <div class="box" :style="left">
        <EvaluationTeacher ref="class" @go="go"></EvaluationTeacher>
        <div class="editor" v-if="edit_show">
            <div class="edit-form" :style="{height:editFormHeight + 'px',top:editorTop,maxHeight:370 + 'px'}">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                    <div class="input-group scroll">
                        <div v-for="(content,index) in evaluate_content" :key="index">
                            <span>{{ content.content }}</span>
                            <el-select v-model="content.selectedValue" placeholder="请选择" size="mini">
                                <el-option
                                    v-for="item in option"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>

                    </div>
                </div>
                <div class="btn-group">
                    <el-button type="primary" size="small" @click="submit">确定</el-button>
                    <el-button size="small" @click="close">取消</el-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import EvaluationTeacher from "@/components/EvaluationTeacher.vue";
import {bus} from '../main'

export default {
    data() {
        return {
            left: {},
            // 编辑框
            edit_show: false,
            title: '添加印章',
            class_id: '',
            score: null,
            base_url: 'class/class/?',
            student_list: [],
            current_id: 0,
            option: [
                {
                    label: '优秀',
                    value: 1
                },
                {
                    label: '良好',
                    value: 2
                },
                {
                    label: '合格',
                    value: 3
                },
                {
                    label: '不合格',
                    value: 4
                },
            ],
            name: '',
            student_id: '',
            evaluate_content: null,
            editFormHeight: 75,
            row: null
        }
    },
    components: {
        EvaluationTeacher
    },
    created() {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        this.$axios.get('basic_data/evaluate_content/')
            .then(response => {
                this.evaluate_content = response.results.results.map(content => ({...content, selectedValue: null}));
                this.editFormHeight += this.evaluate_content.length * 43
            })
        if (this.$global.isCollapse) {
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        } else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted(event, callback) {
        // 事件总线注册监听时间
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
    },
    methods: {
        editorTop() {
            return `calc(50vh - ${this.editFormHeight / 2}px)`;
        },
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
        go() {
            this.title = this.$refs.class.title
            this.row = this.$refs.class.row
            console.log(this.row)
            this.guide_list = []
            this.major_list = []
            this.edit_show = true
        },
        close() {
            this.evaluate_content = this.evaluate_content.map(content => {
                return {...content, selectedValue: null}
            })
            this.edit_show = false
        },
        submit() {
            let flag = false
            let breakFlag = false
            this.evaluate_content.forEach(content => {
                if (!content.selectedValue) {
                    flag = true
                    return false
                }
            })
            if (flag) {
                this.$message.error('请全部选择完成再提交')
                return false
            }
            this.$confirm('教师评价提交后不可修改，是否确认提交?', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.evaluate_content.forEach(content => {
                    this.$axios.post('evaluation/evaluation_teacher/', {
                        teacher: this.row.get_teacher_id,
                        course: this.row.course,
                        student: this.$cookies.get('teacher'),
                        evaluation: content.id,
                        score: content.selectedValue
                    }).then(response => {

                    }).catch(error => {
                        this.$message.error('评价失败，请稍后再试！')
                        breakFlag = true
                        return false
                    })
                })
                if (!breakFlag) {
                    this.$message.success('评价成功')
                    this.edit_show = false
                }
            }).catch(() => {
                this.$message.info('取消提交!')
            })
        }
    }
}
</script>

<style scoped>
.box::-webkit-scrollbar {
    width: 0;
}

.scroll {
    overflow: auto;
    max-height: 280px;
}

.box {
    overflow: auto;
    height: calc(100vh - 110px);

}

.editor {
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(200, 200, 200, 0.4);
    position: fixed;
    top: 0;
    left: 0;
}

.edit-form {
    width: 420px;
    height: 330px;
    background: white;
    position: fixed;
    top: calc(50vh - 170px);
    left: calc(50vw - 210px);
    border-radius: 4px;
    padding: 15px;
}

.editor .edit-form span {
    font-weight: bold;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.input-group {
    margin: 20px 0;
    text-align: left;
    font-size: 14px;
}

.el-select {
    width: 80px;
    margin-bottom: 15px;
}

span {
    display: inline-block;
    width: 300px;
    text-align: left;
    margin-right: 15px;
}

.btn-group {
    margin-top: 10px;
    text-align: right;
    margin-right: 5px;
}

.title {
    width: 200px;
    text-align: center;
}
</style>