<template>
    <div class="error-container">
        <div class="error-content">
            <div class="error-decor">
                <span class="circle"></span>
                <span class="circle"></span>
                <span class="circle"></span>
                <span class="circle"></span>
                <span class="circle"></span>
                <span class="circle"></span>
                <span class="circle"></span>
                <span class="circle"></span>
                <span class="circle"></span>
            </div>
            <h1>404</h1>
            <h2>Oops! Page Not Found</h2>
            <p>Sorry, but the page you are looking for does not exist.</p>
            <router-link to="/login" class="btn">Go to Login</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error404',
};
</script>

<style scoped>
.error-container {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #f0f4f7; /* Light blue background */
}

.error-content {
    text-align: center;
}

.error-decor {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #3f72af; /* Blue color */
    border-radius: 50%;
    margin: 5px;
    animation: bounce 1s infinite alternate;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}

.error-content h1 {
    font-size: 120px;
    margin-bottom: 20px;
    color: #3f72af; /* Blue color */
}

.error-content h2 {
    font-size: 36px;
    margin-bottom: 20px;
    margin-top: 40px;
    color: #333; /* Dark gray color */
}

.error-content p {
    font-size: 18px;
    margin-bottom: 30px;
    color: #555; /* Medium gray color */
}

.btn {
    display: inline-block;
    padding: 12px 24px;
    background-color: #3f72af; /* Blue color */
    color: #fff; /* White color */
    border-radius: 4px;
    text-decoration: none;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #2c5282; /* Darker blue color on hover */
}
</style>
