<template>
    <div class="login-container">
        <el-card class="login-card" shadow="hover">
            <h2>登录</h2>
            <div class="login-tabs">
                <div :class="{ 'active': activeTab === 'passwordLogin' }" @click="switchTab('passwordLogin')">
                    <span>密码登录</span>
                </div>
                <div :class="{ 'active': activeTab === 'verifyCodeLogin' }" @click="switchTab('verifyCodeLogin')">
                    <span>验证码登录</span>
                </div>
            </div>
            <el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-width="0">
                <el-form-item v-if="activeTab === 'passwordLogin'" prop="username">
                    <el-input v-model="loginForm.username" placeholder="请输入工号/学号/手机号" clearable
                              prefix-icon="el-icon-phone"
                              class="custom-input"></el-input>
                </el-form-item>
                <el-form-item v-if="activeTab === 'passwordLogin'" prop="password">
                    <el-input v-model="loginForm.password" placeholder="请输入密码" type="password" clearable
                              prefix-icon="el-icon-lock" class="custom-input"></el-input>
                </el-form-item>
                <el-form-item v-if="activeTab === 'verifyCodeLogin'" prop="username">
                    <el-input v-model="loginForm.username" placeholder="请输入学号/手机号" clearable
                              prefix-icon="el-icon-phone"
                              class="custom-input"></el-input>
                </el-form-item>
                <el-form-item v-if="activeTab === 'verifyCodeLogin'" prop="verifyCode">
                    <el-input v-model="loginForm.verifyCode" placeholder="请输入验证码" clearable
                              prefix-icon="el-icon-safety" class="custom-input verificationCode"></el-input>
                    <el-button @click="send_sms"
                               class="verify-code-button verificationCodeBtn primary-button-style">
                        {{ sms_interval }}
                    </el-button>
                </el-form-item>
                <el-form-item>
                    <div class="login-button-wrapper">
                        <el-button type="primary" @click="login" class="login-button">登录</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import {bus} from '../main'

export default {
    data() {
        return {
            activeTab: 'passwordLogin',
            loginForm: {
                username: '',
                password: '',
                verifyCode: ''
            },
            loginRules: {
                username: [{required: true, message: '请输入您的账号', trigger: 'blur'}],
                password: [{required: true, message: '请输入密码', trigger: 'blur'}],
                verifyCode: [{required: true, message: '请输入验证码', trigger: 'blur'}]
            },
            sms_interval: '请输入验证码',
            is_send: true,
        };
    },
    methods: {
        send_sms() { //发送短信获取验证码
            // this.is_send必须允许发生验证码，才可以往下执行逻辑
            if (!this.is_send) return; //当is_send为false时，直接return出去
            this.is_send = false;  //点击事件执行到这行后，让按钮立即禁用
            let sms_interval_time = 60;
            this.sms_interval = '发送中.....';

            //发送ajax请求
            this.$axios({
                url: '/user/mobile/send_sms/',
                method: 'get',
                params: {
                    //携带的参数 同send_sms/?mobile=this.mobile
                    mobile: this.loginForm.username
                }
            }).then(() => {
                let timer = setInterval(() => {
                    if (sms_interval_time < 1) {
                        clearInterval(timer)  //清除定时器
                        this.sms_interval = '获取验证码';  //设置值
                        this.is_send = true;  //可以重新点击发送功能事件
                    } else {
                        sms_interval_time--;
                        //把值重新赋予
                        this.sms_interval = `${sms_interval_time}秒后再发`
                    }
                }, 1000)
            }).catch(() => {
                this.sms_interval = '频率过快'
                this.is_send = true;
            })
        }, // 发送短信事件结束位置
        login() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    // 登录逻辑
                    if (this.activeTab === 'passwordLogin') {
                        this.$axios.post('user/login/login/', {
                            username: this.loginForm.username,
                            password: this.loginForm.password
                        })
                            .then(res => {

                                this.$cookies.set('token', res.token, '7d')
                                this.$cookies.set('username', res.username, '7d')
                                this.$cookies.set('role', res.role, '7d')
                                this.$cookies.set('department', res.department, '7d')
                                this.$cookies.set('teacher', res.teacher, '7d')
                                bus.$emit('isPermissionChanged')
                                bus.$emit('isCookiesChanged')
                                this.$global.choice_list = [['首页', '/home']]
                                this.$global.choice = 0
                                bus.$emit('choiceListChanged', this.$global.choice_list)
                                bus.$emit('choiceChanged', this.$global.choice);
                            })
                            .catch(error => {
                                this.$message.error('账号或密码错误')
                                this.loginForm.username = ''
                                this.loginForm.password = ''
                                this.loginForm.verifyCode = ''
                            })
                    } else {
                        if (!(this.loginForm.username && this.loginForm.verifyCode)) {
                            this.$message({
                                message: '手机号或验证码必填',
                                type: 'warning',
                                duration: 1000
                            })
                            return false
                        }

                        //发送ajax请求
                        this.$axios.post('user/login/sms_login/', {
                            phone: this.loginForm.username,
                            code: this.loginForm.verifyCode
                        }).then(res => {
                            this.$cookies.set('token', res.token, '7d')
                            this.$cookies.set('username', res.username, '7d')
                            this.$cookies.set('role', res.role, '7d')
                            this.$cookies.set('department', res.department, '7d')
                            this.$cookies.set('teacher', res.teacher, '7d')
                            bus.$emit('isPermissionChanged')
                            bus.$emit('isCookiesChanged')
                            this.$global.choice_list = [['首页', '/home']]
                            this.$global.choice = 0
                            bus.$emit('choiceListChanged', this.$global.choice_list)
                            bus.$emit('choiceChanged', this.$global.choice);
                        }).catch(() => {
                            this.$message.error('手机号或验证码错误')
                            this.loginForm.username = ''
                            this.loginForm.password = ''
                            this.loginForm.verifyCode = ''
                        })
                    }
                }
            });
        },
        switchTab(tab) {
            this.activeTab = tab;
            this.$nextTick(() => {
                this.$refs.loginForm.clearValidate();
            });
        }
    }
};
</script>

<style scoped>
.login-container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../assets/img/bg.jpg') no-repeat center center fixed;
    background-size: cover;
}

.login-card {
    width: 400px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
}

.login-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.login-tabs > div {
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 10px 0;
}

.login-tabs > div.active {
    border-bottom: 2px solid #409EFF;
}

.el-form-item {
    margin-bottom: 20px;
}

.el-button {
    width: 100%;
}

.verify-code-button {
    padding: 5px 20px;
}

.login-button {
    background-color: #409EFF;
}

.custom-input .el-input__inner {
    padding: 10px;
    font-size: 14px;
}

.verify-code-button-wrapper {
    display: flex;
    align-items: center;
}

.login-button-wrapper {
    display: flex;
    justify-content: center;
}

.verificationCode {
    width: 65%;
}

.verificationCodeBtn {
    height: 39px;
    border-radius: 3px;
    width: 35%;
    background: #409EFF;
    border: 0;
    color: white;
    cursor: pointer;
}

span {
    font-weight: bold;
}


.primary-button-style, .primary-button-style:focus, .primary-button-style:hover {
    background: #409EFF;
    border-color: #2979ff;
    color: white;
}

</style>
