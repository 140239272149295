<template>
    <div class="box" :style="left">
        <Notice @close="close" @go="go" ref="notice"></Notice>
        <div class="editor" v-if="edit_show">
            <div class="edit-form">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                </div>
                <div class="input-group">
                    <span>公告标题</span>
                    <el-input
                        placeholder="请输入公告标题"
                        v-model="notice_title"
                        size="small"
                        :disabled="![2,3].includes(Number(this.permission))"
                        clearable>
                    </el-input>
                    <span>公告类型</span>
                    <el-select v-model="notice_type" clearable placeholder="请选择" size="small"
                               :disabled="![2,3].includes(Number(this.permission))">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="status-radio">
                    <span>状态</span>
                    <el-radio-group v-model="radio" :disabled="![2,3].includes(Number(this.permission))">
                        <el-radio :label=1>正常</el-radio>
                        <el-radio :label=0>关闭</el-radio>
                    </el-radio-group>
                </div>
                <div class="edit">
                    <span>内容</span>
                    <div>
                        <EditView ref="edit" :content="row.content"></EditView>
                    </div>

                </div>
                <div class="btn-group" v-if="[2,3].includes(Number($cookies.get('role')))">
                    <el-button type="primary" size="small" @click="submit">确定</el-button>
                    <el-button size="small" @click="close">取消</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderView from "@/components/AsideView.vue";
import Notice from "@/components/Notice.vue";
import EditView from "@/components/editView.vue";
import {bus} from "@/main";

export default {
    data() {
        return {
            title: '添加公告',
            edit_show: false,
            radio: 1,
            options: [{
                value: 1,
                label: '公告'
            }, {
                value: 2,
                label: '通知'
            }],
            notice_title: '',
            notice_type: '',
            row: {},
            left: {},
            permission:null
        }
    },
    components: {
        HeaderView,
        Notice,
        EditView
    },
    created () {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        this.permission = this.$cookies.get('role')
        if (this.$global.isCollapse){
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        }else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted() {
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
    },

    methods: {
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
        close() {
            this.notice_title = ''
            this.radio = ''
            this.notice_type = ''
            this.$refs.notice.row = {}
            this.edit_show = false
        },
        go() {
            this.edit_show = true
            this.row = this.$refs.notice.row
            this.notice_title = this.row.title
            this.notice_type = this.row.type
            this.radio = this.row.status
            this.title = this.$refs.notice.title


        },

        submit() {
            if (this.$refs.notice.create_change === 1) {
                this.$axios({
                    method: 'post',
                    url: 'notice/notice/',
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        title: this.notice_title,
                        status: this.radio,
                        type: this.notice_type,
                        content: this.$refs.edit.editor.getHtml()
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `notice/notice/?page=${this.$refs.notice.current_now}`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    })
                        .then(response => {
                            this.$refs.notice.total = response.results.count
                            this.$refs.notice.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    });
                })
            } else {
                this.$axios({
                    method: 'put',
                    url: `/notice/notice/${this.$refs.notice.row.id}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        title: this.notice_title,
                        status: this.radio,
                        type: this.notice_type,
                        content: this.$refs.edit.editor.getHtml()
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: 'notice/notice/',
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.$refs.notice.total = response.results.count
                            this.$refs.notice.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    })
                })
            }
            this.close()
        }
    }
}
</script>

<style scoped>
.box::-webkit-scrollbar {
    width:0;
}
/deep/.el-input.is-disabled .el-input__inner {
    /* 禁用状态下输入框的背景色 */
    background-color: #fff;
    /* 禁用状态下输入框的文字颜色 */
    color: #666;
    /* 其他样式设置，根据需要自行添加 */
}
.box{
    overflow: auto;
    height: calc(100vh - 110px);

}
.editor {
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(200, 200, 200, 0.4);
    position: fixed;
    top: 0;
    left: 0;
}

.editor .edit-form {
    width: 800px;
    height: 550px;
    background: white;
    position: fixed;
    top: calc(50vh - 275px);
    left: calc(50vw - 400px);
    border-radius: 3px;
    padding: 15px;
}

.editor .edit-form span {
    font-weight: bold;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.input-group {
    margin: 20px 0;
    text-align: left;
    font-size: 14px;
}

.el-input {
    width: 300px;
    margin-right: 15px;
}

.el-select {
    width: 300px;
}

.status-radio {
    text-align: left;
}

span {
    display: inline-block;
    width: 60px;
    text-align: right;
    margin-right: 15px;
}

.title {
    width: 100px;
}

.edit {
    display: flex;
    margin-top: 20px;
}

.edit span {
    display: flex;
    width: 60px;
    justify-content: right;
    margin-right: 15px;

}

.edit div {
    display: block;
    width: 710px;
    margin-top: 1px;
}

.btn-group {
    text-align: right;
    margin: 15px;
}
</style>

