import axios from 'axios'
import ElementUI from "element-ui";

axios.defaults.baseURL = 'http://47.95.198.199:8000/api/v1/'

const request = axios.create({
    timeout: 5000,
    headers: {
        'Content-Type': "application/json; charset=utf-8"
    }

})
//在请求发出之前进行拦截，
// 从localStorage中取出token携带在请求头中
// request.interceptors.request.use(config => {
//     config.headers['Authorization'] ='Bearer ' +  localStorage.getItem('token')
//     return config
// })

//在请求响应后进行拦截，
// 如果响应数据中的status为100，表示成功，不做处理，如果不是100，使用elementui显示错误信息
// 如果响应对象的状态码为401，重定向到登陆页面，只要有错误，就打印错误信息
request.interceptors.response.use(
    response => {
        let res = response.data
        if (res.code === 100) {
            return response.data
        } else {
            // Element.Message.error(!res.msg ? '请求服务器异常,请联系管理员' : res.msg)
            return Promise.reject(!res.msg ? '请求服务器异常,请联系管理员' : res.msg)
        }
    },
    error => {
        console.log(error)
        if (error.response.data) {
            error.massage = error.response.data.msg
        }
        if (error.response.status === 401) {
            router.push("/login")
        }
        Element.Message.error(error.massage, {duration: 3000})
        return Promise.reject(error)
    }
)
export default request
