<template>
    <div>
        <div class="content-table">
            <div class="btn-group">
                <el-button type="primary" plain icon="el-icon-plus" @click="addARecord" size="mini">新增</el-button>
            </div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                :border=true
                tooltip-effect="dark"
                @current-change=""
                :header-cell-style="{background:'#f8f8f9'}"
                @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    type="index"
                    label="序号"
                    width="70"
                    align="center"
                    :index="indexMethod"
                >
                </el-table-column>
                <el-table-column
                    label="开始时间"
                    align="center"
                    prop="start_date"
                    min-width="120"
                >
                </el-table-column>
                <el-table-column
                    label="结束时间"
                    align="center"
                    prop="end_date"
                    min-width="120"
                >
                </el-table-column>
                <el-table-column
                    label="天数"
                    align="center"
                    prop="days"
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    label="事由"
                    align="center"
                    prop="reason"
                    min-width="240"
                >
                </el-table-column>
                <el-table-column
                    label="状态"
                    align="center"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.get_status===0" class='primary'>审核中</div>
                        <div v-else-if="scope.row.get_status===1" class="status-close">已驳回</div>
                        <div v-else class="senior">已通过</div>
                    </template>
                </el-table-column>

                <el-table-column
                    label="操作"
                    align="center"
                    width="200"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <div class="operation-btn-group">
                            <div class="disabled-box" v-if="scope.row.get_status===0">
                            <el-button icon="el-icon-edit" type="text"
                                       @click="change(scope.row)" class="disabled">审核中
                            </el-button>
                            </div>
                            <el-button v-else-if="scope.row.get_status===1" icon="el-icon-edit" type="text"
                                       @click="change(scope.row)" >修改
                            </el-button>
                            <el-button v-else icon="el-icon-view" type="text" @click="check(scope.row)">查看结果
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,30]"
                    :page-size.sync="page_size"
                    :current-page.sync="current_now"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <div class="check_result" v-if="form_show">
            <div class="box">
                <el-result icon="success" title="申请通过" :subTitle="remark">
                    <template slot="extra">
                        <el-button type="primary" size="medium" @click="form_close">返回</el-button>
                    </template>
                </el-result>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "@/main";

export default {
    data() {
        return {
            class_id_search: '',
            name_search: '',
            guide_list: [],
            status_search: null,
            guide_search: null,
            // 表格数据
            tableData: [],
            // 分页数据
            total: 0,
            current_now: 1,
            page_size: 10,
            // 按钮可用状态
            delete_disabled: true,
            change_disabled: true,
            // 选择项
            multipleSelection: [],
            // 调用编辑框
            create_change: null,
            title: null,
            row: {},
            base_url: 'apply/leave/?',
            form_show:false,
            remark:'',
            socket:null
        }
    },
    created() {
        this.$axios({
            method: 'get',
            url: `${this.base_url}size=${this.page_size}&applicant=${this.$cookies.get('teacher')}&role=${this.$cookies.get('role')}`
        }).then(response => {
            this.tableData = response.results.results
            this.total = response.results.count
        })

    },
    watch: {
        multipleSelection() {
            this.change_disabled = this.multipleSelection.length !== 1;
            this.delete_disabled = this.multipleSelection.length === 0;
        }
    },
    mounted() {
        setInterval( () => {
            this.$global.socket.onmessage = event =>{
                console.log(event.data)
            }
        },1000)

    },
    methods: {
        indexMethod(index) {
            return index + 1;
        },
        handleSizeChange(val) {
            this.page_size = val
            this.current_now = 1
            this.$axios({
                method: 'GET',
                url: `${this.base_url}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        handleCurrentChange(val) {
            this.$axios({
                method: 'get',
                url: `${this.base_url}&page=${val}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                    this.total = response.results.count; // 更新总条目数
                })
                .catch(error => {

                })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        search() {
            let search = ''
            if (this.class_id_search) {
                search += `class_id=${this.class_id_search}&`
            }
            if (this.name_search) {
                search += `name=${this.name_search}&`
            }
            if (this.guide_search !== null) {
                search += `guide=${this.guide_search[1]}&`
            }
            if (this.status_search !== null) {
                search += `status=${this.status_search}&`
            }

            this.$axios({
                method: 'get',
                url: `${this.base_url}&${search}size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        refresh() {
            this.name_search = ''
            this.status_search = null
            this.guide_search = null
            this.class_id_search = ''
            this.$axios({
                method: 'get',
                url: `${this.base_url}&size=${this.page_size}`
            }).then(response => {
                this.tableData = response.results.results
                this.total = response.results.count
            })
        },
        change(row) {
            this.title = "请假申请单"
            this.create_change = 0
            this.row = row
            this.$emit('go')
        },
        addARecord() {
            this.title = "请假申请单"
            this.create_change = 1
            this.$emit('go')
        },
        check(row){
            this.remark ='审批意见： ' + row.get_remark
            this.form_show = !this.form_show
        },
        form_close () {
            this.form_show = !this.form_show
        }
    },


}
</script>

<style scoped>
.seal-search {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
}


.el-input {
    width: 200px;
    margin: 0 15px;
}

.el-select {
    margin: 0 15px;
}

.content-table {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 15px;
    padding: 20px;
    text-align: left;

}

.btn-group {
    margin-bottom: 15px;
}

.primary {
    font-size: 12px;
    background: #e7faf0;
    border: 1px solid #d0f5e0;
    color: #13ce66;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.status-close {
    font-size: 12px;
    background: #ffeded;
    border: 1px solid #ffdbdb;
    color: #ff4949;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.senior {
    font-size: 12px;
    background: #e8f4ff;
    border: 1px solid #d1e9ff;
    color: #1890ff;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.intermediate {
    font-size: 12px;
    background: #fff8e6;
    border: 1px solid #fff1cc;
    color: #ffba00;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.pagination {
    text-align: right;
    padding-top: 20px;
}

.operation-btn-group .el-button {
    height: 20px;
    font-size: 12px;
    padding: 0;
}
.check_result {
    width: 100vw;
    height: 100vh;
    background: rgba(200,200,200,.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
}
.box {
    width: 300px;
    height: 240px;
    background: white;
    position: fixed;
    top: calc(50vh - 120px);
    left: calc(50vw - 150px);
    border-radius: 4px;
    padding: 15px;
}
.disabled-box {
    cursor: not-allowed;
}
.disabled {
    pointer-events: none;
    color: rgb(180,180,180);
}
</style>