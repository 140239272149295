<template>
    <div class="box" :style="left">
        <Major ref="major" @go="go"></Major>
        <div class="editor" v-if="edit_show">
            <div class="edit-form">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                    <div class="input-group">
                        <span>专业编号</span>
                        <el-input
                            v-model="major_id"
                            type="input"
                            size="small"
                            placeholder="请输专业编号"
                        >
                        </el-input>
                        <span>专业名称</span>
                        <el-input
                            v-model="major_name"
                            type="input"
                            size="small"
                            placeholder="请输入专业名称"
                        >
                        </el-input>
                        <span>所属院系</span>
                        <el-select v-model="department" clearable placeholder="请选择所属院系" size="small">
                            <el-option
                                v-for="item in department_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <span>带 头 人</span>
                        <el-cascader
                            v-model="choice_head"
                            placeholder="试试搜索：数学"
                            :options="teacher_list"
                            filterable
                            class="width-25"
                            size="small"
                        ></el-cascader>
                        <span>专业学制</span>
                        <el-input
                            type="Number"
                            v-model="duration"
                            size="small"
                            :controls="false"
                            placeholder="请输入专业学制"
                            class="no-spinner width-25"

                        ></el-input>
                        <span>授予学位</span>
                        <el-select v-model="degree_type" clearable placeholder="请选择所属院系" size="small">
                            <el-option
                                v-for="item in degree_type_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="remark-box">
                            <span class="remark">专业简介</span>
                            <el-input
                                class='el-input-textarea'
                                type="textarea"
                                placeholder="请输入内容"
                                v-model="textarea"
                                maxlength="60"
                                rows="6"
                                show-word-limit
                                resize="none"
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="btn-group">
                        <el-button type="primary" size="small" @click="submit">确定</el-button>
                        <el-button size="small" @click="close">取消</el-button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Major from "@/components/Major.vue";
import {bus} from '../main'

export default {
    data() {
        return {
            major_id: '',
            status:'',
            left: {},
            // 编辑框
            edit_show: false,
            title: '添加专业',
            options: [{
                value: 1,
                label: '初级'
            }, {
                value: 2,
                label: '中级'
            }, {
                value: 3,
                label: '高级'
            }],
            major_name: null,
            textarea: "",
            department: '',
            department_list: [],
            choice_head: null,
            teacher_list: [],
            duration: "",
            degree_type: '',
            degree_type_list: [
                {
                    value: 0,
                    label: '无学位'
                }, {
                    value: 1,
                    label: '学士学位'
                }, {
                    value: 2,
                    label: '硕士学位'
                }, {
                    value: 3,
                    label: '博士学位'
                }
            ],
            base_url:'personnel/major/?'

        }
    },
    components: {
        Major
    },
    created() {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        if (![1,2,3].includes(Number(this.$cookies.get('role')))){
            this.base_url = `${this.base_url}&department=${this.$cookies.get('department')}`
        }
        if (this.$global.isCollapse) {
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        } else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted(event, callback) {
        // 事件总线注册监听时间
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
    },
    methods: {
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
        go() {
            this.title = this.$refs.major.title
            if (this.$refs.major.create_change === 0) {
                this.major_id = this.$refs.major.row.major_id
                this.major_name = this.$refs.major.row.name
                this.department = this.$refs.major.row.department
                this.choice_head = [this.$refs.major.row.department, this.$refs.major.row.head]
                this.textarea = this.$refs.major.row.description
                this.duration = this.$refs.major.row.duration
                this.degree_type = this.$refs.major.row.degree_type
                this.status = this.$refs.major.row.status
            }
            this.teacher_list = []
            this.department_list = []
            this.$axios.get('personnel/department/all/')
                .then(res => {
                    res.results.forEach(async res => { // 使用async关键字
                        this.department_list.push({
                            value: res.id,
                            label: res.name
                        })
                        let result = []; // 用于存储请求结果
                        await this.$axios({
                            method: 'get',
                            url: `personnel/teacher/all/?department=${res.id}`,
                            headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                        }).then(response => {
                            response.results.forEach(item => {
                                result.push({
                                    value: item.id,
                                    label: item.name
                                });
                            });
                        })
                        this.teacher_list.push({
                            value: res.id,
                            label: res.name,
                            children: result // 将请求结果作为children返回
                        });
                    });
                })
            this.edit_show = true
        },
        close() {
            this.major_id = ''
            this.major_name = ''
            this.department = ''
            this.choice_head = null
            this.textarea = ''
            this.duration = ''
            this.degree_type = ''
            this.edit_show = false
        },
        submit() {
            if (this.$refs.major.create_change === 1) {
                this.$axios({
                    method: 'post',
                    url: 'personnel/major/',
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        major_id: this.major_id,
                        name: this.major_name,
                        department: this.department,
                        head: this.choice_head[1],
                        description: this.textarea,
                        duration: this.duration,
                        degree_type: this.degree_type,
                        status:1
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&page=${this.$refs.major.current_now}&size=${this.$refs.major.page_size}`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    })
                        .then(response => {
                            this.$refs.major.total = response.results.count
                            this.$refs.major.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    });
                })
            } else {
                this.$axios({
                    method: 'put',
                    url: `personnel/major/${this.$refs.major.row.id}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        major_id: this.major_id,
                        name: this.major_name,
                        department: this.department,
                        head: this.choice_head[1],
                        description: this.textarea,
                        duration: this.duration,
                        degree_type: this.degree_type,
                        status:this.status
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&page=${this.$refs.major.current_now}&size=${this.$refs.major.page_size}`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.$refs.major.total = response.results.count
                            this.$refs.major.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    })
                })
            }
            this.close()
        }
    }
}
</script>

<style scoped>
.box::-webkit-scrollbar {
    width: 0;
}

.box {
    overflow: auto;
    height: calc(100vh - 110px);

}

.editor {
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(200, 200, 200, 0.4);
    position: fixed;
    top: 0;
    left: 0;
}

.edit-form {
    width: 400px;
    height: 530px;
    background: white;
    position: fixed;
    top: calc(50vh - 265px);
    left: calc(50vw - 200px);
    border-radius: 4px;
    padding: 15px;
}

.editor .edit-form span {
    font-weight: bold;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.input-group {
    margin: 20px 0;
    text-align: left;
    font-size: 14px;
}

.el-input, .el-input-textarea, .el-cascader {
    width: 300px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.el-select {
    width: 300px;
    margin-bottom: 15px;
}

span {
    display: inline-block;
    width: 60px;
    text-align: right;
    margin-right: 15px;
}

.remark-box {
    position: relative;
}

.remark {
    position: absolute;
    top: 0;
}

.el-input-textarea {
    position: relative;
    left: 75px;
}

.btn-group {
    margin-top: -20px;
    text-align: right;
    margin-right: 25px;
}

</style>