<template>
    <div class="box" :style="left">
        <SearchScore ref="teacher" @go="go"></SearchScore>
        <div class="editor" v-if="edit_show">
            <div class="edit-form">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                    <div class="input-group">
                        <span>课程编码</span>
                        <el-input
                            v-model="course_id"
                            type="input"
                            size="small"
                            placeholder="请输可成编码"
                            class="width-25"
                        >
                        </el-input>
                        <span>课程名称</span>
                        <el-input
                            v-model="name"
                            type="input"
                            size="small"
                            placeholder="请输入姓名"
                            class="width-20"
                        >
                        </el-input>
                        <span>课程类型</span>
                        <el-select v-model="type" clearable placeholder="请选择课程类型" size="small" class="width-25">
                            <el-option
                                v-for="item in type_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <span>学 时</span>
                        <el-input
                            v-model="time"
                            type="input"
                            size="small"
                            placeholder="请输入课程学时"
                            class="width-25"
                        >
                        </el-input>
                        <span>学 分</span>
                        <el-input
                            v-model="credit"
                            type="input"
                            size="small"
                            placeholder="请输入课程学分"
                            class="width-20"
                        >
                        </el-input>
                        <span>授课教师</span>
                        <el-cascader
                            v-model="teacher"
                            placeholder="试试搜索：数学"
                            :options="teacher_list"
                            filterable
                            class="width-25"
                            size="small"
                        ></el-cascader>
                        <span>授课方式</span>
                        <el-select v-model="teaching_methods" clearable placeholder="请选择授课方式" size="small" class="width-25">
                            <el-option
                                v-for="item in method_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <span>是否选修</span>
                        <el-select v-model="course_selection_method" clearable placeholder="请选择是否选修" size="small" class="width-20">
                            <el-option
                                v-for="item in selection_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>

                    </div>
                    <div class="btn-group">
                        <el-button type="primary" size="small" @click="submit">确定</el-button>
                        <el-button size="small" @click="close">取消</el-button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import SearchScore from "@/components/SearchScore.vue";
import {bus} from '../main'

export default {
    data() {
        return {
            left: {},
            // 编辑框
            edit_show: false,
            title: '添加印章',
            course_id:'',
            type:'',
            name:'',
            type_list:[],
            time:'',
            credit:'',
            teacher:'',
            teacher_list:[],
            teaching_methods:'',
            method_list:[{
                value:0,
                label:'线上'
            },{
                value:1,
                label:'面授'
            }],
            course_selection_method: '',
            selection_list: [{
                value:0,
                label:'选修'
            },{
                value:1,
                label:'必修'
            }],
            textarea: "",
            role: '',
            role_list: [],
            base_url: 'course/course/?status=0'
        }
    },
    components: {
        SearchScore
    },
    created() {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        if (Number(this.$cookies.get('role')) === 1) {
            this.base_url = `${this.base_url}&department=${this.$cookies.get('department')}`
        }
        if (this.$global.isCollapse) {
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        } else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted(event, callback) {
        // 事件总线注册监听时间
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
    },
    methods: {
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
        go() {
            this.title = this.$refs.teacher.title
            if (this.$refs.teacher.create_change === 0) {
                this.course_id = this.$refs.teacher.row.course_id
                this.name = this.$refs.teacher.row.name
                this.type = this.$refs.teacher.row.type
                this.time = this.$refs.teacher.row.time
                this.credit = this.$refs.teacher.row.credit
                this.teacher = [this.$refs.teacher.row.get_teacher_department,this.$refs.teacher.row.teacher]
                this.course_selection_method = this.$refs.teacher.row.course_selection_method
                this.teaching_methods = this.$refs.teacher.row.teaching_methods
            }
            this.position_list = []
            this.type_list = []
            this.teacher_list =[]
            this.$axios.get('basic_data/course_type/all/')
                .then(response => {
                    response.results.forEach(values => {
                        this.type_list.push({value: values.id, label: values.name})
                    })
                })
            this.$axios.get('rbac/role/')
                .then(response => {
                    response.results.forEach(values => {
                        this.role_list.push({value: values.id, label: values.name})
                    })
                })
            this.$axios.get('personnel/department/all/')
                .then(res => {
                    res.results.forEach(async res => { // 使用async关键字
                        let result = []; // 用于存储请求结果
                        await this.$axios({
                            method: 'get',
                            url: `personnel/teacher/all/?status=2&department=${res.id}`,
                            headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                        }).then(response => {
                            response.results.forEach(item => {
                                result.push({
                                    value: item.id,
                                    label: item.name
                                });
                            });
                        })
                        this.teacher_list.push({
                            value: res.id,
                            label: res.name,
                            children: result // 将请求结果作为children返回
                        });
                    });
                })
            this.edit_show = true
        },
        close() {
            this.course_id = ''
            this.name = ''
            this.type = ''
            this.time = ''
            this.credit = ''
            this.teacher = ''
            this.course_selection_method = ''
            this.teaching_methods = ''
            this.department = ''
            this.edit_show = false
        },
        submit() {
            if (this.$refs.teacher.create_change === 1) {
                this.$axios({
                    method: 'post',
                    url: 'course/course/',
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        course_id:this.course_id,
                        name:this.name,
                        type:this.type,
                        time:this.time,
                        credit:this.credit,
                        teacher:this.teacher[1],
                        course_selection_method:this.course_selection_method,
                        teaching_methods:this.teaching_methods,
                        department:this.department
                    },
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&page=${this.$refs.teacher.current_now}&size=${this.$refs.teacher.page_size}/?status=0`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    })
                        .then(response => {
                            this.$refs.teacher.total = response.results.count
                            this.$refs.teacher.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    });
                })
            } else {
                this.$axios({
                    method: 'put',
                    url: `course/course/${this.$refs.teacher.row.id}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        course_id:this.course_id,
                        name:this.name,
                        type:this.type,
                        time:this.time,
                        credit:this.credit,
                        teacher:this.teacher[1],
                        course_selection_method:this.course_selection_method,
                        teaching_methods:this.teaching_methods,
                        department:this.department
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&page=${this.$refs.teacher.current_now}&size=${this.$refs.teacher.page_size}/?status=0`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.$refs.teacher.total = response.results.count
                            this.$refs.teacher.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    })
                })
            }
            this.close()
        }
    }
}
</script>

<style scoped>
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.box::-webkit-scrollbar {
    width: 0;
}

.box {
    overflow: auto;
    height: calc(100vh - 110px);

}

.editor {
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(200, 200, 200, 0.4);
    position: fixed;
    top: 0;
    left: 0;
}

.edit-form {
    width: 1040px;
    height: 230px;
    background: white;
    position: fixed;
    top: calc(50vh - 115px);
    left: calc(50vw - 530px);
    border-radius: 4px;
    padding: 20px;
}

.editor .edit-form span {
    font-size: 14px;
    display: inline-block;
    font-weight: bold;
    width: 100px;
    text-align: right;
    margin-right: 10px;
}

.editor .edit-form .title {
    font-size: 16px;
    width: 200px;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.input-group {
    height: 140px;
    margin: 20px 0;
    text-align: left;
    overflow: auto;
}

.el-input, .el-input-textarea {
    margin-bottom: 20px;
}

.width-55 {
    width: 550px;
}

.width-25 {
    width: 250px;
}

.width-20 {
    width: 190px;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 190px;
}

.start.el-input, .start.el-input__inner {
    width: 190px;
}

.el-input-textarea {
    width: 900px;
    margin-right: 15px;
    margin-bottom: 20px;
    position: relative;
    left: 110px;
}

.remark-box {
    position: relative;
}

.remark {
    position: absolute;
    top: 0;
}

.upload-demo {
    margin-top: -15px;
    margin-left: 110px;
    margin-bottom: 10px;
}


.title {
    display: inline-block;
    font-weight: bold;
}

.btn-group {
    float: right;
    margin-right: 15px;
}
</style>