import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/global.css'
import axios from '@/http'
import * as echarts from 'echarts'
import cookies from 'vue-cookies'
import GLOBAL from '@/components/global.vue'
import icon from './assets/css/icon/font_ba2p2sk4hid/iconfont.css'
Vue.prototype.$cookies = cookies
Vue.prototype.$axios = axios
Vue.prototype.$global = GLOBAL
Vue.prototype.$echarts = echarts
Vue.prototype.$base_url = "http://127.0.0.1:8000/api/v1/"
export const bus = new Vue()
Vue.use(ElementUI);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
