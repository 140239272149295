<template>
    <div class="box" :style="left">
        <CourseTable ref="course_type"></CourseTable>

    </div>
</template>

<script>
import CourseTable from "@/components/CourseTable.vue";
import {bus} from '../main'

export default {
    data() {
        return {
            left: {},
            // 编辑框
            edit_show: false,
            title: '添加印章',
            options: [{
                value: 0,
                label: '停用'
            }, {
                value: 1,
                label: '正常'
            }],
            status: null,
            course_type_name: null,
            textarea: ""
        }
    },
    components: {
        CourseTable
    },
    created () {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        if (this.$global.isCollapse){
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        }else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted(event, callback) {
        // 事件总线注册监听时间
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
    },
    methods: {
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
    }
}
</script>

<style scoped>
.box::-webkit-scrollbar {
    width:0;
}
.box{
    overflow: auto;
    height: calc(100vh - 110px);

}

.editor .edit-form span {
    font-weight: bold;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

span {
    display: inline-block;
    width: 60px;
    text-align: right;
    margin-right: 15px;
}

</style>