<template>
    <div>
        <div class="notice-search">
            公告标题
            <el-input
                placeholder="请输入公告标题"
                v-model="title_search"
                size="small"
                clearable>
            </el-input>
            操作人员
            <el-input
                placeholder="请输入操作人员"
                v-model="creator_search"
                size="small"
                clearable>
            </el-input>
            类型
            <el-select v-model="type_search" clearable placeholder="请选择" size="small">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
            <el-button icon="el-icon-refresh" plain size="mini" @click="refresh">重置</el-button>
        </div>
        <div class='content-table'>
            <div class="btn-group">
                <el-button type="primary" plain icon="el-icon-plus" @click="addARecord">新增</el-button>
                <el-button type="success" plain icon="el-icon-edit" @click="change" :disabled="change_disabled">修改
                </el-button>
                <el-button type="danger" plain icon="el-icon-delete" :disabled="delete_disabled" @click="delete_all">
                    删除
                </el-button>
            </div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                :border=true
                tooltip-effect="dark"
                @current-change=""
                :header-cell-style="{background:'#f8f8f9'}"
                @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    label="序号"
                    width="60"
                    align="center"
                    prop="id"
                >
                </el-table-column>
                <el-table-column
                    prop="title"
                    label="公告标题"
                    width="420"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="get_status"
                    label="状态"
                    align="center"
                    width="100"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.status === 1" class="status-normal">{{ scope.row.get_status }}</div>
                        <div v-else class="status-close">{{ scope.row.get_status }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="get_creator"
                    label="创建者"
                    align="center"
                    width="140"
                >
                </el-table-column>
                <el-table-column
                    prop="create_time"
                    label="创建时间"
                    align="center"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="get_type"
                    label="公告类型"
                    align="center"
                    width="100"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.type === 1" class="type-announcement">{{ scope.row.get_type }}</div>
                        <div v-else class="type-notice">{{ scope.row.get_type }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div class="operation-btn-group">
                            <el-button icon="el-icon-view" type="text" @click="show_notice(scope.row)">查看</el-button>
                            <el-button icon="el-icon-edit" type="text" @click="go(scope.row)" v-if="[2,3].includes(Number($cookies.get('role')))">修改</el-button>
                            <el-button icon="el-icon-delete" type="text" @click="dele(scope.row.id)" v-if="[2,3].includes(Number($cookies.get('role')))">删除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,30]"
                    :page-size.sync="page_size"
                    :current-page.sync="current_now"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title_search: '',
            creator_search: '',
            type_search: '',
            tableData: [],
            multipleSelection: [],
            options: [{
                value: 1,
                label: '公告'
            }, {
                value: 2,
                label: '通知'
            }],
            title: '',
            currentRow: null,
            row: {},
            change_disabled: true,
            delete_disabled: true,
            total: 0,
            create_change: 1, // 1新增，2修改,
            current_now: 1,
            page_size: 10,
            html: '',
            is_show:false
        }
    },
    created() {
        this.$axios({
            method: 'GET',
            url: `notice/notice/?size=${this.page_size}`,
            headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
        })
            .then(response => {
                this.total = response.results.count
                this.tableData = response.results.results
            })
            .catch(error => {

            })
    },
    watch: {
        multipleSelection() {
            this.change_disabled = this.multipleSelection.length !== 1;
            this.delete_disabled = this.multipleSelection.length === 0;
        }
    },
    methods: {
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        refresh() {
            this.type_search = ''
            this.title_search = ''
            this.creator_search = ''
            this.$axios({
                method: 'GET',
                url: `notice/notice/?size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.total = response.results.count
                    this.tableData = response.results.results
                }).catch(error => {
            })
        },
        handleSizeChange(val) {
            this.page_size = val
            this.$axios({
                method: 'GET',
                url: `notice/notice/?size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        handleCurrentChange(val) {
            this.$axios({
                method: 'get',
                url: `notice/notice/?page=${val}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                    this.total = response.results.count; // 更新总条目数
                })
                .catch(error => {

                })
        },
        addARecord() {
            this.title = '添加公告'
            this.create_change = 1
            this.$emit('go')
        },
        go(row) {
            this.title = '修改公告'
            this.row = row
            this.create_change = 2
            this.$emit('go')
        },
        show_notice (row) {
            this.title = '查看公告'
            this.row = row
            this.create_change = 2
            this.$emit('go')
        },
        dele(row) {
            this.row = row
            this.$confirm('此操作将永久删除该通知, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios({
                    method: "delete",
                    url: `notice/notice/${row}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                })
                    .then(response => {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'

                        });
                        this.current_now = 1
                        this.refresh()
                    })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        search() {
            let search = ''
            if (this.title_search) {
                search += `title=${this.title_search}&`
            }
            if (this.creator_search) {
                search += `creator=${this.creator_search}&`
            }
            if (this.type_search) {
                search += `type=${this.type_search}&`
            }

            this.$axios({
                method: 'get',
                url: `notice/notice/?${search}size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        change() {
            this.go(this.multipleSelection[0])
        },
        delete_all() {
            this.$confirm('此操作将永久删除通知, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.multipleSelection.forEach((value, index) => {
                    this.$axios({
                        method: 'delete',
                        url: `notice/notice/${value.id}/`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {

                            this.refresh()
                        })
                });
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                })
                this.current_now = 1
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        }
    }
}
</script>

<style scoped>
.show-notice {
    width: 100vw;
    height: 100vh;
    background: rgba(200,200,200,0.5);
    z-index: 1001;
    position: fixed;
    left: 0;
    top:0;
}
.btn-group {
    text-align: left;
    margin-bottom: 20px;
}

.btn-group .el-button {
    width: 72px;
    font-size: 13px;
    height: 30px;
    padding: 0;
}

.content-table {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
}

.notice-search {
    margin-top: 10px;
    margin-bottom: 14px;
    font-size: 14px;
    text-align: left;

}

.notice-search .el-input, .notice-search .el-select {
    width: 170px;
    margin-left: 10px;
    margin-right: 30px;
}

.operation-btn-group .el-button {
    height: 20px;
    font-size: 12px;
    padding: 0;
}

.pagination {
    text-align: right;
    padding-top: 20px;
}

.status-normal {
    font-size: 12px;
    background: #e8f4ff;
    border: 1px solid #d1e9ff;
    color: #1890ff;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.status-close {
    font-size: 12px;
    background: #ffeded;
    border: 1px solid #ffdbdb;
    color: #ff4949;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.type-announcement {
    font-size: 12px;
    background: #e7faf0;
    border: 1px solid #d0f5e0;
    color: #13ce66;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.type-notice {
    font-size: 12px;
    background: #fff8e6;
    border: 1px solid #fff1cc;
    color: #ffba00;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}
</style>