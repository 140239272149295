import { render, staticRenderFns } from "./Position.vue?vue&type=template&id=225c47ba&scoped=true"
import script from "./Position.vue?vue&type=script&lang=js"
export * from "./Position.vue?vue&type=script&lang=js"
import style0 from "./Position.vue?vue&type=style&index=0&id=225c47ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225c47ba",
  null
  
)

export default component.exports