<template>
    <div>
        <div class="seal-search">
            <span style="margin-right: 20px">学院</span>
            <el-cascader
                v-model="group_search"
                placeholder="试试搜索：数学"
                :options="class_list"
                filterable
                class="width-25"
                size="small"
                style="margin-right: 10px"
            ></el-cascader>
            学年
            <el-select v-model="school_year" clearable placeholder="请选择" size="small">
                <el-option
                    v-for="item in school_year_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            学期
            <el-select v-model="semester" clearable placeholder="请选择" size="small">
                <el-option
                    v-for="item in semester_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
            <el-button icon="el-icon-refresh" plain size="mini" @click="refresh">重置</el-button>
        </div>
        <div class="content-table">
            <div class="btn-group">
                <a :href="fileUrl" class="download-btn" style="margin-right: 10px">
                    <el-button type="warning" plain icon="el-icon-plus" size="mini">
                        下载Excel模版
                    </el-button>
                </a>
                <el-button type="primary" plain icon="el-icon-plus" @click="mutilADD" size="mini">Excel批量导入
                </el-button>
            </div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                :border=true
                tooltip-effect="dark"
                @current-change=""
                :header-cell-style="{background:'#f8f8f9'}"
                @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    label="序号"
                    width="70"
                    align="center"
                    prop="id"
                >
                </el-table-column>
                <el-table-column
                    label="班级编号"
                    align="center"
                    prop="get_group_info.group_id"
                    min-width="120"
                >
                </el-table-column>
                <el-table-column
                    label="班级名称"
                    align="center"
                    prop="get_group_info.name"
                    width="240"
                >
                </el-table-column>
                <el-table-column
                    label="班级人数"
                    align="center"
                    prop="get_group_info.number_students"
                    min-width="100"
                >
                </el-table-column>
                <el-table-column
                    label="课程id"
                    align="center"
                    prop="get_course_id"
                    min-width="100"
                >
                </el-table-column>
                <el-table-column
                    label="课程名称"
                    align="center"
                    prop="get_course"
                    min-width="100"
                >
                </el-table-column>


                <el-table-column
                    label="操作"
                    align="center"
                    width="200"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <div class="operation-btn-group">
                            <el-button icon="el-icon-edit" type="text" @click="change(scope.row)">录入</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,30]"
                    :page-size.sync="page_size"
                    :current-page.sync="current_now"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <div class="upload" v-if="upload_show">
            <div class="upload-box">
                <div class="close">
                    <i class="el-icon-close" @click="mutilADD"></i>
                </div>
                <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :on-change="fileChange"
                    :auto-upload="false"
                    :file-list="file_list"
                    :limit="1">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传.xlsx文件</div>
                </el-upload>
                <el-button type="primary" size="small" @click="submitUpload">确认</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "@/main";

export default {
    data() {
        return {
            // 表格数据
            tableData: [],
            // 分页数据
            total: 0,
            current_now: 1,
            page_size: 10,
            // 按钮可用状态
            delete_disabled: true,
            change_disabled: true,
            // 选择项
            multipleSelection: [],
            // 调用编辑框
            create_change: null,
            title: null,
            row: {},
            group_search: '',
            class_list: [],
            base_url: `course/course_table/?teacher=${this.$cookies.get("teacher")}`,
            semester: '',
            semester_list: [{
                label: '第一学期',
                value: 1
            }, {
                label: '第二学期',
                value: 2
            }],
            school_year: '',
            school_year_list: [],
            fileUrl:'',
            upload_show:false
        }
    },
    created() {
        this.$axios.get('personnel/department/all/?')
            .then(res => {
                res.results.forEach(async res => { // 使用async关键字
                    this.class_list.push({
                        value: res.id,
                        label: res.name
                    });
                });
            })
        this.$axios.get('basic_data/school_year/')
            .then(response => {
                response.results.forEach(item => {
                    this.school_year_list.push({
                        value: item.id,
                        label: item.name
                    })
                })
            })
    },
    mounted() {
        this.fileUrl = `${this.$base_url}download/score.xlsx`
    },
    watch: {

        multipleSelection() {
            this.change_disabled = this.multipleSelection.length !== 1;
            this.delete_disabled = this.multipleSelection.length === 0;
        }
    },
    methods: {
        handleSizeChange(val) {
            this.page_size = val
            this.current_now = 1
            this.$axios({
                method: 'GET',
                url: `${this.base_url}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        handleCurrentChange(val) {
            this.$axios({
                method: 'get',
                url: `${this.base_url}&page=${val}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                    this.total = response.results.count; // 更新总条目数
                })
                .catch(error => {

                })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        search() {
            this.tableData = []
            let search = ''
            if (!this.school_year) {
                this.$message.error('请先选择学年')
                return false
            }
            if (!this.semester) {
                this.$message.error('请先选择学期')
                return false
            }
            if (this.group_search) {
                search += `&department_id=${this.group_search[0]}&school_year=${this.school_year}&semester=${this.semester}`
            } else {
                search += `&school_year=${this.school_year}&semester=${this.semester}`
            }

            this.$axios({
                method: 'get',
                url: `${this.base_url}&${search}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        refresh() {
            this.group_search = ''
            this.school_year = ''
            this.semester = ''
            this.tableData = []

        },
        delete_all() {

            this.$confirm('此操作将永久删除该职称, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.multipleSelection.forEach((value, index) => {
                    this.$axios({
                        method: 'delete',
                        url: `personnel/teacher/${value.id}/`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.refresh()
                        })
                });
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                })
                this.current_now = 1
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        dele(row) {
            this.row = row
            this.$confirm('此操作将永久删除该职称, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios({
                    method: "delete",
                    url: `personnel/teacher/${row}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                })
                    .then(response => {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'

                        });
                        this.current_now = 1
                        this.refresh()
                    })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        change(row) {
            this.title = "修改成绩"
            this.create_change = 0
            this.row = row
            this.$emit('go')
        },
        addARecord() {
            this.title = "添加成绩"
            this.create_change = 1
            this.$emit('go')
        },
        mutilADD () {
            this.file_list = []
            this.upload_show = !this.upload_show
        },
        fileChange(file, file_list) {
            this.file_list = file_list
        },
        submitUpload() {
            const fileType = this.file_list[0].name.substring(this.file_list[0].name.lastIndexOf('.'))
            if (fileType.toLowerCase() !== '.xlsx') {
                this.$message.error('文件必须为.xlsx类型')
                this.file_list = []
                return false
            }
            let formData = new FormData();
            this.file_list.forEach(item => {
                formData.append("file", item.raw);
            });

            this.$axios({
                method: 'post',
                url: 'score/score/file_update/',
                data: formData,
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(response => {
                    this.$message.success('上传成功！')
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&size=${this.page_size}`
                    }).then(response => {
                        this.tableData = response.results.results
                        this.total = response.results.count
                    })
                }
            ).catch(error => {
                    this.$message.error('上传失败，请稍后重试')
                }
            )
            this.upload_show = false
        }
    },


}
</script>

<style scoped>
.seal-search {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
}


.el-input {
    width: 200px;
    margin: 0 15px;
}

.el-select {
    margin: 0 15px;
}

.content-table {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 15px;
    padding: 20px;
    text-align: left;

}

.btn-group {
    margin-bottom: 15px;
}

.primary {
    font-size: 12px;
    background: #e7faf0;
    border: 1px solid #d0f5e0;
    color: #13ce66;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.status-close {
    font-size: 12px;
    background: #ffeded;
    border: 1px solid #ffdbdb;
    color: #ff4949;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.senior {
    font-size: 12px;
    background: #e8f4ff;
    border: 1px solid #d1e9ff;
    color: #1890ff;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.intermediate {
    font-size: 12px;
    background: #fff8e6;
    border: 1px solid #fff1cc;
    color: #ffba00;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.pagination {
    text-align: right;
    padding-top: 20px;
}

.operation-btn-group .el-button {
    height: 20px;
    font-size: 12px;
    padding: 0;
}
.upload {
    width: 100vw;
    height: 100vh;
    background: rgba(200, 200, 200, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.upload-box {
    width: 400px;
    height: 350px;
    background: white;
    margin-top: calc(50vh - 175px);
    margin-left: calc(50vw - 200px);
    position: relative;

}

.upload-box > .close {

    font-size: 20px;
    line-height: 30px;
    width: 100%;
    text-align: right;
    height: 30px;
    margin-bottom: 30px;

}

.upload-box > .close > i {
    margin-right: 15px;
    margin-top: 10px;
    cursor: pointer;
}

.upload-box .el-button {
    position: absolute;
    bottom: 10px;
    right: 24px;
}

.download-btn {
    margin: 0 10px;
}

</style>