<template>
    <div class="box" :style="left">
        <ClassRoom ref="class" @go="go"></ClassRoom>
        <div class="editor" v-if="edit_show">
            <div class="edit-form">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                    <div class="input-group">
                        <span>教室编号</span>
                        <el-input
                            v-model="room_id"
                            type="input"
                            size="small"
                            placeholder="请输入班级号"
                            class="width-25"
                        >
                        </el-input>
                        <span>班级名称</span>
                        <el-input
                            v-model="name"
                            type="input"
                            size="small"
                            placeholder="请输入班级名称"
                            class="width-20"
                        >
                        </el-input>
                        <span>位 置</span>
                        <el-input
                            v-model="address"
                            type="input"
                            size="small"
                            placeholder="请输入班级名称"
                            class="width-20"
                        ></el-input>
                        <span>门牌号</span>
                        <el-input
                            v-model="house_number"
                            type="input"
                            size="small"
                            placeholder="请输入班级人数"
                            class="width-20"
                        ></el-input>
                        <div class="remark-box">
                            <span class="remark">备 注</span>
                            <el-input
                                class='el-input-textarea'
                                type="textarea"
                                placeholder="请输入内容"
                                v-model="textarea"
                                maxlength="60"
                                rows="6"
                                show-word-limit
                                resize="none"
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="btn-group">
                        <el-button type="primary" size="small" @click="submit">确定</el-button>
                        <el-button size="small" @click="close">取消</el-button>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import ClassRoom from "@/components/ClassRoom.vue";
import {bus} from '../main'

export default {
    data() {
        return {
            left: {},
            // 编辑框
            edit_show: false,
            title: '添加印章',
            room_id: '',
            name: null,
            house_number: '',
            address:'',
            base_url: 'class/class/?',
            textarea:''
        }
    },
    components: {
        ClassRoom
    },
    created() {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        if (Number(this.$cookies.get('role')) === 1) {
            this.base_url = `${this.base_url}&department=${this.$cookies.get('department')}`
        } else if (Number(this.$cookies.get('role')) === 5) {
            this.base_url = `${this.base_url}&guide=${this.$cookies.get('teacher')}`
        } else if (Number(this.$cookies.get('role')) === 4) {
            this.base_url = `${this.base_url}&head=${this.$cookies.get('teacher')}`
        }
        if (this.$global.isCollapse) {
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        } else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted(event, callback) {
        // 事件总线注册监听时间
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
    },
    methods: {
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
        go() {
            this.title = this.$refs.class.title
            if (this.$refs.class.create_change === 0) {
                this.room_id = this.$refs.class.row.room_id
                this.name = this.$refs.class.row.name
                this.address = this.$refs.class.row.position
                this.house_number = this.$refs.class.row.house_number
                this.textarea = this.$refs.class.row.remark
            }
            this.guide_list = []
            this.major_list = []
            this.$axios.get('personnel/department/all/')
                .then(res => {
                    res.results.forEach(async res => { // 使用async关键字
                        let result = []; // 用于存储请求结果
                        let result2 = [];
                        await this.$axios({
                            method: 'get',
                            url: `personnel/teacher/all/?department=${res.id}`,
                            headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                        }).then(response => {
                            response.results.forEach(item => {
                                result.push({
                                    value: item.id,
                                    label: item.name
                                });
                            });
                        })
                        this.guide_list.push({
                            value: res.id,
                            label: res.name,
                            children: result // 将请求结果作为children返回
                        });
                        this.$axios.get(`personnel/major/all/?department=${res.id}`)
                            .then(response => {
                                response.results.forEach(value => {
                                    result2.push({
                                        value: value.id,
                                        label: value.name
                                    })
                                })
                            })
                        this.major_list.push({
                            value: res.id,
                            label: res.name,
                            children: result2 // 将请求结果作为children返回
                        });

                    });
                })
            this.edit_show = true
        },
        close() {
            this.room_id = ''
            this.name = ''
            this.address = ''
            this.house_number = ''
            this.textarea = ''
            this.edit_show = false
        },
        submit() {
            if (this.$refs.class.create_change === 1) {
                this.$axios({
                    method: 'post',
                    url: 'basic_data/room_manage/',
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        room_id:this.room_id,
                        name:this.name,
                        position:this.address,
                        house_number:this.house_number,
                        remark:this.textarea,
                        use_status:1
                    },
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `basic_data/room_manage/`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    })
                        .then(response => {
                            this.$refs.class.total = response.results.count
                            this.$refs.class.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    });
                })
            } else {
                this.$axios({
                    method: 'put',
                    url: `basic_data/room_manage/${this.$refs.class.row.id}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        room_id:this.room_id,
                        name:this.name,
                        position:this.address,
                        house_number:this.house_number,
                        remark:this.textarea,
                        use_status:1
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `basic_data/room_manage/`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.$refs.class.total = response.results.count
                            this.$refs.class.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    })
                })
            }
            this.close()
        }
    }
}
</script>

<style scoped>
.box::-webkit-scrollbar {
    width: 0;
}

.box {
    overflow: auto;
    height: calc(100vh - 110px);

}

.editor {
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(200, 200, 200, 0.4);
    position: fixed;
    top: 0;
    left: 0;
}

.edit-form {
    width: 400px;
    height: 426px;
    background: white;
    position: fixed;
    top: calc(50vh - 213px);
    left: calc(50vw - 200px);
    border-radius: 4px;
    padding: 15px;
}

.editor .edit-form span {
    font-weight: bold;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.input-group {
    margin: 20px 0;
    text-align: left;
    font-size: 14px;
}

.el-input, .el-input-textarea, .el-cascader, .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 300px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.el-select {
    width: 300px;
    margin-bottom: 15px;
}

span {
    display: inline-block;
    width: 60px;
    text-align: right;
    margin-right: 15px;
}

.remark-box {
    position: relative;
}

.remark {
    position: absolute;
    top: 0;
}

.el-input-textarea {
    position: relative;
    left: 75px;
}

.btn-group {
    margin-top: -20px;
    text-align: right;
    margin-right: 25px;
}

.title {
    width: 200px;
    text-align: center;
}
</style>