<template>
    <div class="box" :style="left">
        <EnterScore ref="class" @go="go"></EnterScore>
        <div class="editor" v-if="edit_show">
            <div class="edit-form">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                    <div class="input-group">
                        <span>班级编号</span>
                        <el-input
                            v-model="this.$refs.class.row.get_group_info.group_id"
                            type="input"
                            size="small"
                            disabled
                            placeholder="请输入班级编号"
                            class="width-25"
                        >
                        </el-input>
                        <span>班级名称</span>
                        <el-input
                            v-model="this.$refs.class.row.get_group_info.name"
                            type="input"
                            size="small"
                            disabled
                            placeholder="请输入班级名称"
                            class="width-20"
                        >
                        </el-input>
                        <span>学生学号</span>
                        <el-input
                            v-model="student_id"
                            type="input"
                            size="small"
                            placeholder="请输入学生学号"
                            class="width-20"
                        >
                        </el-input>
                        <span>学生姓名</span>
                        <el-input
                            v-model="name"
                            type="input"
                            size="small"
                            placeholder="请输入学生姓名"
                            class="width-20"
                        >
                        </el-input>
                        <span>分 数</span>
                        <el-input
                            v-model="score"
                            type="input"
                            size="small"
                            placeholder="请输入课程成绩"
                            class="width-20"
                        >
                        </el-input>
                    </div>
                </div>
                <div class="btn-group">
                    <el-button type="primary" size="small" @click="submit">确定</el-button>
                    <el-button size="small" @click="close">取消</el-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import EnterScore from "@/components/EnterScore.vue";
import {bus} from '../main'

export default {
    data() {
        return {
            left: {},
            // 编辑框
            edit_show: false,
            title: '添加印章',
            class_id: '',
            score: null,
            major: '',
            major_list: [],
            guide: null,
            guide_list: [],
            number: '',
            start_date: '',
            end_date: '',
            base_url: 'class/class/?',
            student_list:[],
            current_id:0,
            name:'',
            student_id:''
        }
    },
    components: {
        EnterScore
    },
    created() {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        if (Number(this.$cookies.get('role')) === 1) {
            this.base_url = `${this.base_url}&department=${this.$cookies.get('department')}`
        }else if (Number(this.$cookies.get('role')) === 5) {
            this.base_url = `${this.base_url}&guide=${this.$cookies.get('teacher')}`
        } else if (Number(this.$cookies.get('role')) === 4) {
            this.base_url = `${this.base_url}&head=${this.$cookies.get('teacher')}`
        }
        if (this.$global.isCollapse) {
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        } else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted(event, callback) {
        // 事件总线注册监听时间
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
    },
    methods: {
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
        go() {
            this.title = this.$refs.class.title
            this.guide_list = []
            this.major_list = []
            this.$axios.get(`student/student/all/?group=${this.$refs.class.row.get_group_info.group_id}`)
                .then(response => {
                    response.results.forEach(item => {
                        this.student_list.push({
                            id:item.id,
                            student_id:item.student_id,
                            name:item.name

                        })
                    })
                    this.student_id = this.student_list[this.current_id].student_id
                    this.name = this.student_list[this.current_id].name
                })
            this.edit_show = true
        },
        close() {
            this.class_id = ''
            this.class_name = ''
            this.major = null
            this.guide = null
            this.number = ''
            this.start_date = ''
            this.end_date = ''
            this.edit_show = false
        },
        submit() {
            console.log(this.$refs.class.row.course)
            if (!this.score){
                this.$message.error('请输入学生成绩')
                return false
            }
            this.$axios.post('score/score/',{
                student:this.student_list[this.current_id].id,
                course:this.$refs.class.row.course,
                score:this.score,
                school_year:this.$refs.class.school_year,
                semester:this.$refs.class.semester
            }).then(response => {


                try{
                    this.$message.success('打分成功，跳转下一位')
                    setTimeout(() => {},1000)
                    this.student_id = this.student_list[++ this.current_id].student_id
                    this.name = this.student_list[this.current_id].name
                    this.score = ''
                } catch (e) {
                    this.$message.success('班级打分完成')
                    this.edit_show = false
                }
            })
        }
    }
}
</script>

<style scoped>
.box::-webkit-scrollbar {
    width: 0;
}

.box {
    overflow: auto;
    height: calc(100vh - 110px);

}

.editor {
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(200, 200, 200, 0.4);
    position: fixed;
    top: 0;
    left: 0;
}

.edit-form {
    width: 400px;
    height: 340px;
    background: white;
    position: fixed;
    top: calc(50vh - 170px);
    left: calc(50vw - 200px);
    border-radius: 4px;
    padding: 15px;
}

.editor .edit-form span {
    font-weight: bold;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.input-group {
    margin: 20px 0;
    text-align: left;
    font-size: 14px;
}

.el-input, .el-input-textarea, .el-cascader, .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 300px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.el-select {
    width: 300px;
    margin-bottom: 15px;
}

span {
    display: inline-block;
    width: 60px;
    text-align: right;
    margin-right: 15px;
}

.remark-box {
    position: relative;
}

.remark {
    position: absolute;
    top: 0;
}

.el-input-textarea {
    position: relative;
    left: 75px;
}

.btn-group {
    margin-top: -20px;
    text-align: right;
    margin-right: 25px;
}
.title {
    width: 200px;
    text-align: center;
}
</style>