<template>
    <div class="box" v-if="[7].includes(role)">
        <el-card class="box-card" id="notice">
            <div slot="header" class="clearfix">
                <span style=""><b>通知公告</b></span>
                <a href="notice"><span style="float: right;cursor:pointer"><b>更多 >></b></span></a>
            </div>
            <div class="notice_content">
                <div v-for="notice in notice_list" class="text item line">
                    <a href="">
                        <span>{{ notice.title }}</span>
                        <h2 style="float: right">{{ notice.get_type }}</h2>
                    </a>
                </div>
            </div>
        </el-card>
        <el-card class="box-card" id="info">
            <div slot="header" class="clearfix">
                <h2>个人信息</h2>
            </div>
            <div>
                <el-descriptions class="margin-top" title="" :column="3" size='mini' border>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-user"></i>
                            姓名
                        </template>
                        {{ class_name.name }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-star-off"></i>
                            学号
                        </template>
                        {{ class_name.student_id }}
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            <i v-if="class_name.gender === '0'" class="el-icon-female"></i>
                            <i v-else class="el-icon-male"></i>
                            性别
                        </template>
                        <span v-if="class_name.gender === '0'">
                            女
                        </span>
                        <span v-else>
                            男
                        </span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            民族
                        </template>
                        {{ class_name.nation }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-school"></i>
                            院系
                        </template>
                        {{ class_name.get_department }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            专业班级
                        </template>
                        {{ class_name.get_group }}
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            政治面貌
                        </template>
                        {{ class_name.politics }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-mobile-phone"></i>
                            手机号
                        </template>
                        {{ class_name.phone }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-message"></i>
                            邮箱
                        </template>
                        {{ class_name.email }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            家庭住址
                        </template>
                        {{ class_name.address }}
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </el-card>
        <el-card class="box-card" id="schedule">
            <div slot="header" class="clearfix">
                <h2><b>课表</b>&nbsp;&nbsp;&nbsp;&nbsp;{{ class_name.get_group }}</h2>
            </div>
            <div class="table">
                <el-table
                    :data="scheduleData"
                    style="width: 100%"
                    border
                    stripe
                >
                    <el-table-column
                        prop="time"
                        label="时间"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        v-for="(day, index) in days"
                        :key="index"
                        :label="day"
                    >
                        <template slot-scope="scope">
                            <div v-if="scope.row[dictionary[index + 1]] && scope.row[dictionary[index + 1]].length">
                                <div v-for="item in scope.row[dictionary[index + 1]]" style="margin-top: 15px">
                                    <span style="font-size: 12px">{{ item.get_course }}</span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <el-card class="box-card" id="system">
            <div slot="header" class="clearfix">
                <h2>成绩统计</h2>
            </div>
            <div>
                <span style="font-size: 16px"><b>已修学分/应修学分：{{ credit }} / {{
                        class_name.get_need_credit
                    }}</b></span>
                <div id="credit_chart" style="width: 100%;height: 200px">

                </div>
            </div>

        </el-card>
    </div>
    <div class="box" v-else>
        <el-card class="box-card" id="notice">
            <div slot="header" class="clearfix">
                <span style=""><b>通知公告</b></span>
                <a href="notice"><span style="float: right;cursor:pointer"><b>更多 >></b></span></a>
            </div>
            <div class="notice_content">
                <div v-for="notice in notice_list" class="text item line">
                    <a href="">
                        <span>{{ notice.title }}</span>
                        <h2 style="float: right">{{ notice.get_type }}</h2>
                    </a>
                </div>
            </div>
        </el-card>
        <el-card class="box-card" id="info">
            <div slot="header" class="clearfix">
                <h2>个人信息</h2>
            </div>
            <div>
                <el-descriptions class="margin-top" title="" :column="3" size='mini' border>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-user"></i>
                            姓名
                        </template>
                        {{ class_name.name }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-star-off"></i>
                            工号
                        </template>
                        {{ class_name.teacher_id }}
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            <i v-if="class_name.gender === '0'" class="el-icon-female"></i>
                            <i v-else class="el-icon-male"></i>
                            性别
                        </template>
                        <span v-if="class_name.gender === '0'">
                            女
                        </span>
                        <span v-else>
                            男
                        </span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            出生日期
                        </template>
                        {{ class_name.birthday }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-school"></i>
                            院系
                        </template>
                        {{ class_name.get_department }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            在职状态
                        </template>
                        <div v-if="class_name.status === 0" class="status-close">离职</div>
                        <div v-else-if="class_name.status === 1" class="intermediate">休假</div>
                        <div v-else class="primary">在职</div>
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            职称
                        </template>
                        {{ class_name.get_position }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-mobile-phone"></i>
                            手机号
                        </template>
                        {{ class_name.phone }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-message"></i>
                            邮箱
                        </template>
                        {{ class_name.email }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            家庭住址
                        </template>
                        {{ class_name.address }}
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </el-card>
        <el-card class="box-card" id="schedule">
            <div slot="header" class="clearfix">
                <h2><b>我的代办</b><a href="work_space"><span style="float:right;cursor:pointer"><b>更多 >></b></span></a></h2>
            </div>
            <div class="table">
                <el-table
                    ref="multipleTable"
                    :data="tableData"
                    style="width: 100%"
                    :border=true
                    tooltip-effect="dark"
                    @current-change=""
                    :header-cell-style="{background:'#f8f8f9'}">
                    <el-table-column
                        type="index"
                        label="序号"
                        width="70"
                        align="center"
                        :index="indexMethod"
                    >
                    </el-table-column>
                    <el-table-column
                        label="开始时间"
                        align="center"
                        prop="start_date"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        label="结束时间"
                        align="center"
                        prop="end_date"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        label="天数"
                        align="center"
                        prop="days"
                        width="80"
                    >
                    </el-table-column>
                    <el-table-column
                        label="事由"
                        align="center"
                        prop="reason"
                        min-width="240"
                    >
                    </el-table-column>
                    <el-table-column
                        label="状态"
                        align="center"
                        min-width="120"
                    >
                        <template slot-scope="scope">
                            <div v-if="scope.row.get_status===0" class='primary'>审核中</div>
                            <div v-else-if="scope.row.get_status===1" class="status-close">已驳回</div>
                            <div v-else class="senior">已通过</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <el-card class="box-card" id="system">
            <div slot="header" class="clearfix">
                <span style=""><b>系统信息</b></span>
            </div>
            <div class="system_content">
                <div>
                    <el-descriptions class="margin-top" title="" :column="1" size='mini' border>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                操作系统
                            </template>
                            {{ system_info.system }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                版本
                            </template>
                            {{ system_info.version }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                系统名称
                            </template>
                            {{ system_info.name }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                cpu使用率
                            </template>
                            {{ system_info.cpu }}%
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                内存使用率
                            </template>
                            {{ system_info.memory }}%
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            class_name: '',
            notice_list: '',
            days: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
            scheduleData: [
                {
                    time: '第一节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第二节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第三节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第四节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第五节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第六节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
            ],
            dictionary: {
                1: 'Monday',
                2: 'Tuesday',
                3: 'Wednesday',
                4: 'Thursday',
                5: 'Friday',
                6: 'Saturday',
                7: 'Sunday'
            },
            base_url: `course/course_table/all/?`,
            credit: null,
            role: Number(this.$cookies.get('role')),
            system_info: null,
            tableData:null,
        }
    },
    created() {
        this.$axios({
            method: 'GET',
            url: `notice/notice/?size=5`,
            headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
        })
            .then(response => {
                this.notice_list = response.results.results
            })
            .catch(error => {

            })
        if ([7].includes(this.role)) {
            this.$axios.get(`student/student/${this.$cookies.get('teacher')}/`)
                .then(response => {
                    this.class_name = response.result
                })
            this.$axios({
                method: 'get',
                url: this.base_url,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    response.results.forEach(item => {
                        this.scheduleData[item.teach_time - 1][this.dictionary[item.teach_week]].push(item)
                    })
                })
                .catch(error => {

                })
            this.$axios.get(`score/score/${this.$cookies.get('teacher')}/count_credit/`)
                .then(response => {
                    this.credit = response.result
                    this.creditChartSet();
                })
        } else {
            this.$axios.get('system/system/')
                .then(response => {
                    this.system_info = response.result
                })
            this.$axios.get(`personnel/teacher/${this.$cookies.get('teacher')}/`)
                .then(response => {
                    this.class_name = response.result
                })
            this.$axios({
                method: 'get',
                url: `apply/leave/?user=${this.$cookies.get('teacher')}&status=0`
            }).then(response => {
                console.log(response)
                this.tableData = response.results.results
            })
        }

    },
    watch: {
        credit() {
            this.creditChartSet();
        },
        class_name() {
            this.creditChartSet();
        }
    },
    mounted() {
        this.creditChartSet();
    },
    methods: {
        indexMethod(index) {
            return index + 1;
        },
        creditChartSet() {
            let creditChart = this.$echarts.init(document.getElementById('credit_chart'))
            creditChart.setOption({
                series: [
                    {
                        type: 'pie',
                        color: [
                            '#37A2DA',
                            '#67E0E3',
                            '#9FE6B8',
                            '#FFDB5C',
                            '#ff9f7f',
                            '#fb7293',
                            '#E062AE',
                            '#E690D1',
                            '#e7bcf3',
                            '#9d96f5',
                            '#8378EA',
                            '#96BFFF'
                        ],
                        data: [
                            {
                                value: this.credit,
                                name: '已修学分'
                            },
                            {
                                value: this.class_name.get_need_credit - this.credit,
                                name: '未修学分'
                            }
                        ]
                    }
                ]
            })
        }
    }
}
</script>

<style scoped>
.box {

}

#notice {
    width: 40%;
    height: 280px;
    float: left;
}

#info {
    width: 58%;
    height: 280px;
    float: right;
}

#schedule {
    width: 59%;
    height: auto;
    float: left;
}

#system {
    width: 39%;
    height: auto;
    float: right;
}

#notice, #schedule {
    margin-right: 20px;
}

#notice, #info {
    margin-bottom: 10px;
}

#notice {
    position: relative;
}

.notice_content {
    height: 70%;
    overflow: auto;
}

.line {
    width: 80%;
    margin: 0 auto 10px;
    padding: 0 20px 10px 20px;
    //border-bottom: 1px solid rgba(150, 150, 150, 0.4);
}

.table {
    overflow: auto;
}

.clearfix {
    height: 20%;
}

.el-card__body {
    overflow: auto;
}

h2 {
    text-align: left;
}

h3 {
    float: left;
    margin-right: 38px;
}

.primary {
    font-size: 14px;
    background: #e7faf0;
    border: 1px solid #d0f5e0;
    color: #13ce66;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
    text-align: center;
}

.status-close {
    font-size: 14px;
    background: #ffeded;
    border: 1px solid #ffdbdb;
    color: #ff4949;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
    text-align: center;
}



.intermediate {
    font-size: 14px;
    background: #fff8e6;
    border: 1px solid #fff1cc;
    color: #ffba00;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
    text-align: center;
}
</style>