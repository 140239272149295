<template>
    <div id="app">
        <AsideView></AsideView>
        <HeaderView></HeaderView>
        <router-view/>
    </div>
</template>

<script>
import HeaderView from "@/components/HeaderView.vue";
import AsideView from "@/components/AsideView.vue";
import {bus} from './main'
export default {
    components:{
        HeaderView,
        AsideView
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    display: flex;
}
#right-bar {
    flex: 1;
}
nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}
</style>
