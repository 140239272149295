<template>
    <div>
        <div class="seal-search">
            学年
            <el-select v-model="school_year" clearable placeholder="请选择" size="small">
                <el-option
                    v-for="item in school_year_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            学期
            <el-select v-model="semester" clearable placeholder="请选择" size="small">
                <el-option
                    v-for="item in semester_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
            <el-button icon="el-icon-refresh" plain size="mini" @click="refresh">重置</el-button>
        </div>
        <div class="content-table">

            <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                :border=true
                tooltip-effect="dark"
                @current-change=""
                :header-cell-style="{background:'#f8f8f9'}"
                @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                >
                </el-table-column>

                <el-table-column
                    label="学年"
                    align="center"
                    prop="get_school_year"
                    min-width="120"
                >
                </el-table-column>
                <el-table-column
                    label="学期"
                    align="center"
                    prop="semester"
                    min-width="60"
                >
                </el-table-column>
                <el-table-column
                    label="课程学分"
                    align="center"
                    prop="course.credit"
                    width="90"
                >
                </el-table-column>
                <el-table-column
                    label="课程性质"
                    align="center"
                    prop="course.get_type"
                    min-width="150"
                >

                </el-table-column>
                <el-table-column
                    label="课程名称"
                    align="center"
                    width="260"
                >
                    <template slot-scope="scope">
                        [{{scope.row.course.course_id}}] {{scope.row.course.name}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="考试形式"
                    align="center"
                    prop=""
                    width="100"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.course.exam_form === 0" class="primary">
                            考试
                        </div>
                        <div v-else class="senior">
                            考察
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="综合成绩"
                    align="center"
                    prop="score"
                    width="90"
                >
                </el-table-column>
                <el-table-column
                    label="绩点"
                    align="center"
                    width="100"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.score >= 90">4</div>
                        <div v-else-if="scope.row.score >= 80">3</div>
                        <div v-else-if="scope.row.score >= 70">2</div>
                        <div v-else-if="scope.row.score >= 60">1</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否补考"
                    align="center"
                    width="130"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.course_selection_method === 0" class="status-close">选修</div>
                        <div v-else class="senior">必修</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    align="center"
                    width="200"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <div class="operation-btn-group">
                            <el-button icon="el-icon-edit" type="text" @click="change(scope.row)">修改</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,30]"
                    :page-size.sync="page_size"
                    :current-page.sync="current_now"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "@/main";

export default {
    data() {
        return {
            // 表格数据
            tableData: [],
            // 分页数据
            total: 0,
            current_now: 1,
            page_size: 10,
            // 选择项
            multipleSelection: [],
            // 调用编辑框
            create_change: null,
            title: null,
            row: {},
            base_url:'score/score/?',
            semester: '',
            semester_list: [{
                label: '第一学期',
                value: 1
            }, {
                label: '第二学期',
                value: 2
            }],
            school_year: '',
            school_year_list: [],
        }
    },
    created() {
        if (Number(this.$cookies.get('role')) === 1){
            this.base_url = `${this.base_url}&department=${this.$cookies.get('department')}`
        }
        this.$axios({
            method: 'get',
            url: `${this.base_url}&size=${this.page_size}`
        }).then(response => {
            this.tableData = response.results.results
            this.total = response.results.count
        })
        this.$axios.get('basic_data/school_year/')
            .then(response => {
                response.results.forEach(item => {
                    this.school_year_list.push({
                        value: item.id,
                        label: item.name
                    })
                })
            })
    },
    watch: {

        multipleSelection() {
            this.change_disabled = this.multipleSelection.length !== 1;
            this.delete_disabled = this.multipleSelection.length === 0;
        }
    },
    methods: {
        handleSizeChange(val) {
            this.page_size = val
            this.current_now = 1
            this.$axios({
                method: 'GET',
                url: `${this.base_url}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        handleCurrentChange(val) {
            this.$axios({
                method: 'get',
                url: `${this.base_url}&page=${val}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                    this.total = response.results.count; // 更新总条目数
                })
                .catch(error => {

                })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        search() {
            let search = ''
            if (this.course_id_search) {
                search += `course_id=${this.course_id_search}&`
            }
            if (this.name_search) {
                search += `teacher_name=${this.name_search}&`
            }

            this.$axios({
                method: 'get',
                url: `${this.base_url}&${search}size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        refresh() {
            this.name_search = ''
            this.status_search = null
            this.course_id_search = ''
            this.$axios({
                method: 'get',
                url: `${this.base_url}&size=${this.page_size}`
            }).then(response => {
                this.tableData = response.results.results
                this.total = response.results.count
            })
        },
        delete_all() {

            this.$confirm('此操作将永久删除该职称, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.multipleSelection.forEach((value, index) => {
                    this.$axios({
                        method: 'delete',
                        url: `personnel/teacher/${value.id}/`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.refresh()
                        })
                });
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                })
                this.current_now = 1
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        dele(row) {
            this.row = row
            this.$confirm('此操作将永久删除该职称, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios({
                    method: "delete",
                    url: `personnel/teacher/${row}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                })
                    .then(response => {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'

                        });
                        this.current_now = 1
                        this.refresh()
                    })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        change(row) {
            this.title = "修改职工信息"
            this.create_change = 0
            this.row = row
            this.$emit('go')
        },
        addARecord() {
            this.title = "添加职工"
            this.create_change = 1
            this.$emit('go')
        }
    },


}
</script>

<style scoped>
.seal-search {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
}


.el-input {
    width: 200px;
    margin: 0 15px;
}

.el-select {
    margin: 0 15px;
}

.content-table {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 15px;
    padding: 20px;
    text-align: left;

}

.btn-group {
    margin-bottom: 15px;
}

.primary {
    font-size: 12px;
    background: #e7faf0;
    border: 1px solid #d0f5e0;
    color: #13ce66;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.status-close {
    font-size: 12px;
    background: #ffeded;
    border: 1px solid #ffdbdb;
    color: #ff4949;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.senior {
    font-size: 12px;
    background: #e8f4ff;
    border: 1px solid #d1e9ff;
    color: #1890ff;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.intermediate {
    font-size: 12px;
    background: #fff8e6;
    border: 1px solid #fff1cc;
    color: #ffba00;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.pagination {
    text-align: right;
    padding-top: 20px;
}

.operation-btn-group .el-button {
    height: 20px;
    font-size: 12px;
    padding: 0;
}
</style>