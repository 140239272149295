<template>
    <div class="box" :style="left">
        <Student ref="teacher" @go="go"></Student>
        <div class="editor" v-if="edit_show">
            <div class="edit-form">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                    <div class="input-group">
                        <span>学号</span>
                        <el-input
                            v-model="student_id"
                            type="input"
                            size="small"
                            placeholder="请输入学号"
                            class="width-25"
                        >
                        </el-input>
                        <span>姓 名</span>
                        <el-input
                            v-model="name"
                            type="input"
                            size="small"
                            placeholder="请输入姓名"
                            class="width-20"
                        >
                        </el-input>
                        <span>性别</span>
                        <el-select v-model="gender" clearable placeholder="请选择性别" size="small" class="width-25">
                            <el-option
                                v-for="item in gender_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <span>民 族</span>
                        <el-input
                            v-model="nation"
                            type="input"
                            size="small"
                            placeholder="请输入民族"
                            class="width-25"
                        >
                        </el-input>
                        <span>政治面貌</span>
                        <el-input
                            v-model="politics"
                            type="input"
                            size="small"
                            placeholder="请输入政治面貌"
                            class="width-20"
                        >
                        </el-input>
                        <span>身份证件类型</span>
                        <el-input
                            v-model="document_type"
                            type="input"
                            size="small"
                            placeholder="请输入身份证件类型"
                            class="width-25"
                        >
                        </el-input>
                        <span>身份证件号</span>
                        <el-input
                            v-model="id_card"
                            type="input"
                            size="small"
                            placeholder="请输入身份证件号"
                            class="width-25"
                        >
                        </el-input>
                        <span>出生日期</span>
                        <el-date-picker
                            v-model="birthday"
                            type="date"
                            class="width-20"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                        <span>联系电话</span>
                        <el-input
                            v-model="phone"
                            type="input"
                            size="small"
                            placeholder="请输入联系电话"
                            class="width-25"
                        >
                        </el-input>
                        <span>所在班级</span>
                        <el-cascader
                            v-model="group"
                            placeholder="试试搜索：网工2001"
                            :options="group_list"
                            filterable
                            class="width-55"
                            size="small"
                        ></el-cascader>

                        <span>邮 箱</span>
                        <el-input
                            v-model="email"
                            type="input"
                            size="small"
                            placeholder="请输入联系邮箱"
                            class="width-25"
                        >
                        </el-input>
                        <span>家庭住址</span>
                        <el-input
                            v-model="address"
                            type="input"
                            size="small"
                            placeholder="请输入家庭住址"
                            class="width-55"
                        >
                        </el-input>
                        <span>家庭电话</span>
                        <el-input
                            v-model="family_phone"
                            type="input"
                            size="small"
                            placeholder="请输入家庭联系电话"
                            class="width-25"
                        >
                        </el-input>

                        <span>Q Q</span>
                        <el-input
                            v-model="QQ"
                            type="input"
                            size="small"
                            placeholder="请输入QQ号码"
                            class="width-25"
                        >
                        </el-input>
                    </div>
                    <div class="btn-group">
                        <el-button type="primary" size="small" @click="submit">确定</el-button>
                        <el-button size="small" @click="close">取消</el-button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Student from "@/components/Student.vue";
import {bus} from '../main'

export default {
    data() {
        return {
            left: {},
            // 编辑框
            edit_show: false,
            title: '',
            student_id:'',
            name:'',
            gender:'',
            gender_list:[{
                value:0,
                label:'女'
            },{
                value:1,
                label:'男'
            }],
            politics:'',
            id_card:'',
            birthday:'',
            document_type:'',
            department:'',
            major:'',
            phone:'',
            email:'',
            QQ:'',
            family_phone:'',
            address:'',
            group:'',
            group_list:[],
            teacher: '',
            teacher_list: [],
            nation:'',
            base_url: 'student/student/?'
        }
    },
    components: {
        Student
    },
    created() {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        if (Number(this.$cookies.get('role')) === 1) {
            this.base_url = `${this.base_url}&department=${this.$cookies.get('department')}&size=${this.$refs.teacher.page_size}`
        }
        if (this.$global.isCollapse) {
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        } else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted(event, callback) {
        // 事件总线注册监听时间
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
    },
    methods: {
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
        go() {
            this.title = this.$refs.teacher.title
            if (this.$refs.teacher.create_change === 0) {
                this.student_id = this.$refs.teacher.row.student_id
                this.name = this.$refs.teacher.row.name
                this.gender = this.$refs.teacher.row.gender
                this.politics = this.$refs.teacher.row.politics
                this.id_card = this.$refs.teacher.row.id_card
                this.birthday = this.$refs.teacher.row.birthday
                this.document_type = this.$refs.teacher.row.document_type
                this.department = this.$refs.teacher.row.department
                this.major = this.$refs.teacher.row.major
                this.phone = this.$refs.teacher.row.phone
                this.email = this.$refs.teacher.row.email
                this.QQ = this.$refs.teacher.row.QQ
                this.family_phone = this.$refs.teacher.row.family_phone
                this.address = this.$refs.teacher.row.address
                this.group = [this.$refs.teacher.row.department,this.$refs.teacher.row.major,this.$refs.teacher.row.group]
                console.log(this.$refs.teacher.row)
                console.log(this.group)
                this.teacher  = this.$refs.teacher.row.teacher
                this.nation = this.$refs.teacher.row.nation
            }
            this.group_list = []
            this.teacher_list = []
            this.$axios.get('personnel/department/all/')
                .then(res => {
                    res.results.forEach(async res => { // 使用async关键字
                        let result = []; // 用于存储请求结果
                        await this.$axios({
                            method: 'get',
                            url: `personnel/major/all/?status=1&department=${res.id}`,
                            headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                        }).then(response => {
                            response.results.forEach(item => {
                                let child = []
                                this.$axios.get(`class/class/all/?major=${item.id}`)
                                    .then(returns => {
                                        returns.results.forEach(element => {
                                            child.push({
                                                value:element.id,
                                                label:element.name
                                            })
                                        })
                                        }
                                    )
                                result.push({
                                    value: item.id,
                                    label: item.name,
                                    children: child
                                });
                            });
                        })
                        this.group_list.push({
                            value: res.id,
                            label: res.name,
                            children: result // 将请求结果作为children返回
                        });
                    });
                })
            this.$axios.get('personnel/department/all/')
                .then(res => {
                    res.results.forEach(async res => { // 使用async关键字
                        let result = []; // 用于存储请求结果
                        await this.$axios({
                            method: 'get',
                            url: `personnel/teacher/all/?status=2&department=${res.id}`,
                            headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                        }).then(response => {
                            response.results.forEach(item => {
                                result.push({
                                    value: item.id,
                                    label: item.name
                                });
                            });
                        })
                        this.teacher_list.push({
                            value: res.id,
                            label: res.name,
                            children: result // 将请求结果作为children返回
                        });
                    });
                })
            this.edit_show = true
        },
        close() {
                this.student_id = ''
                this.name = ''
                this.gender = ''
                this.politics = ''
                this.id_card = ''
                this.birthday = ''
                this.document_type = ''
                this.department = ''
                this.major = ''
                this.phone = ''
                this.email = ''
                this.QQ = ''
                this.family_phone = ''
                this.address = ''
                this.group = null
                this.teacher =  null
                this.nation = ''
            this.edit_show = false
        },
        submit() {
            if (this.$refs.teacher.create_change === 1) {
                this.$axios({
                    method: 'post',
                    url: 'student/student/',
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        student_id:this.student_id,
                        name:this.name,
                        gender:this.gender,
                        politics:this.politics,
                        id_card:this.id_card,
                        birthday:this.birthday,
                        document_type:this.document_type,
                        department:this.group[0],
                        major:this.major[1],
                        phone:this.phone,
                        email:this.email,
                        QQ:this.QQ,
                        family_phone:this.family_phone,
                        address:this.address,
                        group:this.group[2],
                        teacher:this.teacher,
                        nation:this.nation
                    },
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&page=${this.$refs.teacher.current_now}&size=${this.$refs.teacher.page_size}&status=1`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    })
                        .then(response => {
                            this.$refs.teacher.total = response.results.count
                            this.$refs.teacher.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    });
                })
            } else {
                this.$axios({
                    method: 'put',
                    url: `student/student/${this.$refs.teacher.row.id}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        student_id:this.student_id,
                        name:this.name,
                        gender:this.gender,
                        politics:this.politics,
                        id_card:this.id_card,
                        birthday:this.birthday,
                        document_type:this.document_type,
                        department:this.group[0],
                        major:this.major[1],
                        phone:this.phone,
                        email:this.email,
                        QQ:this.QQ,
                        family_phone:this.family_phone,
                        address:this.address,
                        group:this.group[2],
                        teacher:this.teacher,
                        nation:this.nation
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&size=${this.$refs.teacher.page_size}&status=1`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.$refs.teacher.total = response.results.count
                            this.$refs.teacher.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    })
                })
            }
            this.close()
        }
    }
}
</script>

<style scoped>
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.box::-webkit-scrollbar {
    width: 0;
}

.box {
    overflow: auto;
    height: calc(100vh - 110px);

}

.editor {
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(200, 200, 200, 0.4);
    position: fixed;
    top: 0;
    left: 0;
}

.edit-form {
    width: 1040px;
    height: 410px;
    background: white;
    position: fixed;
    top: calc(50vh - 205px);
    left: calc(50vw - 530px);
    border-radius: 4px;
    padding: 20px;
}

.editor .edit-form span {
    font-size: 14px;
    display: inline-block;
    font-weight: bold;
    width: 100px;
    text-align: right;
    margin-right: 10px;
}

.editor .edit-form .title {
    font-size: 16px;
    width: 200px;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.input-group {
    height: 320px;
    margin: 20px 0;
    text-align: left;
    overflow: auto;
}

.el-input, .el-cascader, .el-input-textarea {
    margin-bottom: 20px;
}

.width-55 {
    width: 550px;
}

.width-25 {
    width: 250px;
}

.width-20 {
    width: 190px;
}

.el-date-editor.el-input,.el-date-editor.el-input__inner {
    width: 190px;
}

.start.el-input, .start.el-input__inner {
    width: 190px;
}

.el-input-textarea {
    width: 900px;
    margin-right: 15px;
    margin-bottom: 20px;
    position: relative;
    left: 110px;
}

.remark-box {
    position: relative;
}

.remark {
    position: absolute;
    top: 0;
}

.upload-demo {
    margin-top: -15px;
    margin-left: 110px;
    margin-bottom: 10px;
}


.title {
    display: inline-block;
    font-weight: bold;
}

.btn-group {
    float: right;
    margin-right: 15px;
}
</style>