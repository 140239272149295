<template>
    <div class="box" :style="left">
        <CourseType ref="course_type" @go="go"></CourseType>
        <div class="editor" v-if="edit_show">
            <div class="edit-form">
                <div>
                    <span class="title">{{ title }}</span>
                    <i class="el-icon-close" @click="close"></i>
                    <div class="input-group">
                        <span>类型名称</span>
                        <el-input
                            v-model="course_type_name"
                            type="input"
                            size="small"
                            placeholder="请输入类型名称"
                        >
                        </el-input>
                        <span>课程状态</span>
                        <el-select v-model="status" clearable placeholder="请选择类型状态" size="small">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="remark-box">
                            <span class="remark">备 注</span>
                            <el-input
                                class='el-input-textarea'
                                type="textarea"
                                placeholder="请输入内容"
                                v-model="textarea"
                                maxlength="60"
                                rows="6"
                                show-word-limit
                                resize="none"
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="btn-group">
                        <el-button type="primary" size="small" @click="submit">确定</el-button>
                        <el-button size="small" @click="close">取消</el-button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import CourseType from "@/components/CourseType.vue";
import {bus} from '../main'

export default {
    data() {
        return {
            left: {},
            // 编辑框
            edit_show: false,
            title: '添加印章',
            options: [{
                value: 0,
                label: '停用'
            }, {
                value: 1,
                label: '正常'
            }],
            status: null,
            course_type_name: null,
            textarea: ""
        }
    },
    components: {
        CourseType
    },
    created () {
        if (!this.$cookies.get('token')) {
            this.$router.replace('login')
        }
        if (this.$global.isCollapse){
            this.left = {
                position: "absolute",
                left: '74px',
                width: 'calc(100vw - 104px)',
                top: '110px',
                overflow: 'auto',
            }
        }else {
            this.left = {
                position: "absolute",
                left: '210px',
                width: 'calc(100vw - 230px)',
                top: '110px',
                overflow: 'auto',
            }
        }
    },
    mounted(event, callback) {
        // 事件总线注册监听时间
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isCookiesChanged', this.handleIsCookiesChanged)
    },
    methods: {
        handleIsCollapseChanged(newValue) {
            if (newValue) {
                this.left = {
                    position: "absolute",
                    left: '74px',
                    width: 'calc(100vw - 104px)',
                    top: '110px',
                    overflow: 'auto',
                }
            } else {
                this.left = {
                    position: "absolute",
                    left: '210px',
                    width: 'calc(100vw - 230px)',
                    top: '110px',
                    overflow: 'auto'
                }
            }
        },
        go() {
            this.title = this.$refs.course_type.title
            if (this.$refs.course_type.create_change === 0) {
                this.course_type_name = this.$refs.course_type.row.name
                this.status = this.$refs.course_type.row.status
                this.textarea = this.$refs.course_type.row.description
            }
            this.edit_show = true
        },
        close() {
            this.course_type_name = ''
            this.status = null
            this.textarea = ''
            this.edit_show = false
        },
        submit() {
            if (this.$refs.course_type.create_change === 1) {
                this.$axios({
                    method: 'post',
                    url: 'basic_data/course_type/',
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        name: this.course_type_name,
                        status:this.status,
                        description: this.textarea
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `basic_data/course_type/?page=${this.$refs.course_type.current_now}&size=${this.$refs.course_type.page_size}`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    })
                        .then(response => {
                            this.$refs.course_type.total = response.results.count
                            this.$refs.course_type.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    });
                })
            } else {
                this.$axios({
                    method: 'put',
                    url: `basic_data/course_type/${this.$refs.course_type.row.id}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    data: {
                        name: this.course_type_name,
                        status:this.status,
                        description: this.textarea
                    }
                }).then(response => {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                    this.$axios({
                        method: 'get',
                        url: `basic_data/course_type/?page=${this.$refs.course_type.current_now}&size=${this.$refs.course_type.page_size}`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.$refs.course_type.total = response.results.count
                            this.$refs.course_type.tableData = response.results.results
                        })
                }).catch(error => {
                    this.$notify({
                        title: '失败',
                        message: '请正确键入信息',
                        type: 'error'
                    })
                })
            }
            this.close()
        }
    }
}
</script>

<style scoped>
.box::-webkit-scrollbar {
    width:0;
}
.box{
    overflow: auto;
    height: calc(100vh - 110px);

}
.editor {
    width: 100vw;
    height: 100vh;
    z-index: 1000 !important;
    background: rgba(200, 200, 200, 0.4);
    position: fixed;
    top: 0;
    left: 0;
}

.edit-form {
    width: 400px;
    height: 326px;
    background: white;
    position: fixed;
    top: calc(50vh - 163px);
    left: calc(50vw - 200px);
    border-radius: 4px;
    padding: 15px;
}

.editor .edit-form span {
    font-weight: bold;
}

.editor .edit-form i {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.input-group {
    margin: 20px 0;
    text-align: left;
    font-size: 14px;
}

.el-input, .el-input-textarea {
    width: 300px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.el-select {
    width: 300px;
    margin-bottom: 15px;
}

span {
    display: inline-block;
    width: 60px;
    text-align: right;
    margin-right: 15px;
}

.remark-box {
    position: relative;
}

.remark {
    position: absolute;
    top: 0;
}

.el-input-textarea {
    position: relative;
    left: 75px;
}

.btn-group {
    margin-top: -20px;
    text-align: right;
    margin-right: 25px;
}

</style>