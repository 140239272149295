<template>
    <div>
        <div class="seal-search">
            院系编号
            <el-input
                placeholder="请输入院系编号"
                v-model="department_id_search"
                size="small"
                clearable
            ></el-input>
            院系名称
            <el-input
                placeholder="请输入院系名称"
                v-model="name_search"
                size="small"
                clearable
            ></el-input>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
            <el-button icon="el-icon-refresh" plain size="mini" @click="refresh">重置</el-button>
        </div>
        <div class="content-table">
            <div class="btn-group">
                <el-button type="primary" plain icon="el-icon-plus" @click="addARecord" size="mini">新增</el-button>
                <el-button type="success" plain icon="el-icon-edit" @click="change(multipleSelection[0])"
                           :disabled="change_disabled"
                           size="mini">修改
                </el-button>
                <el-button type="danger" plain icon="el-icon-delete" :disabled="delete_disabled" @click="delete_all"
                           size="mini">
                    删除
                </el-button>
            </div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                :border=true
                tooltip-effect="dark"
                @current-change=""
                :header-cell-style="{background:'#f8f8f9'}"
                @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    label="序号"
                    width="70"
                    align="center"
                    prop="id"
                >
                </el-table-column>
                <el-table-column
                    label="院系编号"
                    align="center"
                    prop="department_id"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="院系名"
                    align="center"
                    prop="name"
                    width="250"
                >
                </el-table-column>
                <el-table-column
                    label="院长"
                    align="center"
                    prop="get_head.name"
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    label="办公地址"
                    align="center"
                    prop="location"
                    width="200"
                >
                </el-table-column>
                <el-table-column
                    label="院系电话"
                    align="center"
                    width="120"
                    prop="phone"

                >
                </el-table-column>
                <el-table-column
                    label="院系邮箱"
                    align="center"
                    width="240"
                    prop="email"

                >
                </el-table-column>

                <el-table-column
                    prop="description"
                    label="院系简介"
                    align="center"
                    width="280"
                >
                </el-table-column>
                <el-table-column
                    label="操作"
                    align="center"
                    width="200"
                    fixed='right'
                >
                    <template slot-scope="scope">
                        <div class="operation-btn-group">
                            <el-button icon="el-icon-edit" type="text" @click="change(scope.row)">修改</el-button>
                            <el-button icon="el-icon-delete" type="text" @click="dele(scope.row.id)">删除
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20,30]"
                    :page-size.sync="page_size"
                    :current-page.sync="current_now"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "@/main";

export default {
    data() {
        return {
            name_search: '',
            department_id_search:'',
            // 表格数据
            tableData: [],
            // 分页数据
            total: 0,
            current_now: 1,
            page_size: 10,
            // 按钮可用状态
            delete_disabled: true,
            change_disabled: true,
            // 选择项
            multipleSelection: [],
            // 调用编辑框
            create_change: null,
            title: null,
            row: {}
        }
    },
    created() {
        this.$axios({
            method: 'get',
            url: `personnel/department/?size=${this.page_size}`
        }).then(response => {
            this.tableData = response.results.results
            this.total = response.results.count
        })
    },
    watch: {

        multipleSelection() {
            this.change_disabled = this.multipleSelection.length !== 1;
            this.delete_disabled = this.multipleSelection.length === 0;
        }
    },
    methods: {
        handleSizeChange(val) {
            this.page_size = val
            this.current_now = 1
            this.$axios({
                method: 'GET',
                url: `personnel/department/?size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        handleCurrentChange(val) {
            this.$axios({
                method: 'get',
                url: `personnel/department/?page=${val}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                    this.total = response.results.count; // 更新总条目数
                })
                .catch(error => {

                })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        search() {
            let search = ''
            if (this.name_search) {
                search += `name=${this.name_search}&`
            }
            if (this.department_id_search) {
                search += `department_id=${this.department_id_search}&`
            }

            this.$axios({
                method: 'get',
                url: `personnel/department/?${search}size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        refresh() {
            this.name_search = ''
            this.status_search = null
            this.department_id_search = ''
            this.$axios({
                method: 'get',
                url: `personnel/department/?size=${this.page_size}`
            }).then(response => {
                this.tableData = response.results.results
                this.total = response.results.count
            })
        },
        delete_all() {

            this.$confirm('此操作将永久删除该职称, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.multipleSelection.forEach((value, index) => {
                    this.$axios({
                        method: 'delete',
                        url: `personnel/department/${value.id}/`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                    })
                        .then(response => {
                            this.refresh()
                        })
                });
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                })
                this.current_now = 1
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        dele(row) {
            this.row = row
            this.$confirm('此操作将永久删除该职称, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios({
                    method: "delete",
                    url: `personnel/department/${row}/`,
                    headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
                })
                    .then(response => {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'

                        });
                        this.current_now = 1
                        this.refresh()
                    })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        change(row) {
            this.title = "修改院系"
            this.create_change = 0
            this.row = row
            this.$emit('go')
        },
        addARecord() {
            this.title = "添加院系"
            this.create_change = 1
            this.$emit('go')
        }
    },


}
</script>

<style scoped>
.seal-search {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
}

.el-input {
    width: 200px;
    margin: 0 15px;
}

.el-select {
    margin: 0 15px;
}

.content-table {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 15px;
    padding: 20px;
    text-align: left;

}

.btn-group {
    margin-bottom: 15px;
}

.primary {
    font-size: 12px;
    background: #e7faf0;
    border: 1px solid #d0f5e0;
    color: #13ce66;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.status-close {
    font-size: 12px;
    background: #ffeded;
    border: 1px solid #ffdbdb;
    color: #ff4949;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.senior {
    font-size: 12px;
    background: #e8f4ff;
    border: 1px solid #d1e9ff;
    color: #1890ff;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.intermediate {
    font-size: 12px;
    background: #fff8e6;
    border: 1px solid #fff1cc;
    color: #ffba00;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.pagination {
    text-align: right;
    padding-top: 20px;
}

.operation-btn-group .el-button {
    height: 20px;
    font-size: 12px;
    padding: 0;
}
</style>