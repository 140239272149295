<template>
    <div>
        <div class="content-table">
            <span style="display:inline-block; margin-bottom: 20px; margin-right: 10px">班级</span>
            <el-cascader
                v-model="group"
                placeholder="试试搜索：数字媒体技术"
                :options="class_list"
                filterable
                class="width-25"
                size="small"
                style="margin-right: 10px"
            ></el-cascader>
            学年
            <el-select v-model="school_year" clearable placeholder="请选择" size="small">
                <el-option
                    v-for="item in school_year_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            学期
            <el-select v-model="semester" clearable placeholder="请选择" size="small">
                <el-option
                    v-for="item in semester_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>

            <el-button type="primary" icon="el-icon-search" size="mini" @click="search" style="margin-right: 10px">搜索</el-button>
            <a :href="fileUrl" class="download-btn" style="margin-right: 10px">
                <el-button type="warning" plain icon="el-icon-plus" size="mini">
                    下载Excel模版
                </el-button>
            </a>
            <el-button type="primary" plain icon="el-icon-plus" @click="mutilADD" size="mini">Excel批量导入
            </el-button>
            <el-table
                :data="scheduleData"
                style="width: 100%"
                border
                @cell-click="show_add"
                stripe
            >
                <el-table-column
                    prop="time"
                    label="时间"
                    width="200"
                    height
                    align="center"
                ></el-table-column>
                <el-table-column
                    align="center"
                    v-for="(day, index) in days"
                    :key="index"
                    :label="day"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row[dictionary[index + 1]] && scope.row[dictionary[index + 1]].length">
                            <div v-for="item in scope.row[dictionary[index + 1]]" style="margin-top: 15px">
                                <h3>课程名:{{ item.get_course }}</h3>
                                <h4>{{ item.get_teacher }} {{ item.start_week }} - {{ item.end_week }}周</h4>
                                <h4>{{ item.get_address }} {{ item.get_exam_form }} </h4>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="add" v-if="show">
            <div class="form">
                <div>
                    <span>添加课程</span>
                    <i class="el-icon-close" @click="close"></i>
                </div>
                <span>课 程</span>
                <el-cascader
                    v-model="course"
                    placeholder="试试搜索：高等数学"
                    :options="course_list"
                    filterable
                    class="width-25"
                    size="small"
                ></el-cascader>
                <span>开始周数</span>
                <el-input
                    v-model="start_week"
                    type="input"
                    size="small"
                    placeholder="请输入开始周数"
                ></el-input>
                <span>结束周数</span>
                <el-input
                    v-model="end_week"
                    type="input"
                    size="small"
                    placeholder="请输入结束周数"
                ></el-input>
                <span>上课地址</span>
                <el-cascader
                    v-model="address"
                    placeholder="试试搜索：A103"
                    :options="address_list"
                    filterable
                    class="width-25"
                    size="small"
                ></el-cascader>
                <el-button type="primary" size="small" style="position: absolute; right: 35px;bottom: 10px"
                           @click="submit_add">
                    确认
                </el-button>
            </div>
        </div>
        <div class="upload" v-if="upload_show">
            <div class="upload-box">
                <div class="close">
                    <i class="el-icon-close" @click="mutilADD"></i>
                </div>
                <el-upload
                    class="upload-demo"
                    drag
                    action="#"
                    :on-change="fileChange"
                    :auto-upload="false"
                    :file-list="file_list"
                    :limit="1">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传.xlsx文件</div>
                </el-upload>
                <el-button type="primary" size="small" @click="submitUpload">确认</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "@/main";

export default {
    data() {
        return {
            // 选择器数据
            // 表格数据
            tableData: [],
            // 分页数据
            total: 0,
            current_now: 1,
            page_size: 10,
            // 按钮可用状态
            delete_disabled: true,
            change_disabled: true,
            // 选择项
            multipleSelection: [],
            // 调用编辑框
            create_change: null,
            title: null,
            row: {},
            group: null,
            class_list: [],
            days: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
            scheduleData: [
                {
                    time: '第一节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第二节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第三节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第四节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第五节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第六节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
            ],
            dictionary: {
                1: 'Monday',
                2: 'Tuesday',
                3: 'Wednesday',
                4: 'Thursday',
                5: 'Friday',
                6: 'Saturday',
                7: 'Sunday'
            },
            base_url: 'course/course_table/all/?&',
            show: false,
            course: null,
            course_list: [],
            start_week: '',
            end_week: '',
            address: '',
            address_list: [],
            fileUrl: '',
            teach_time: '',
            teach_week: '',
            semester:null,
            semester_list: [{
                label: '第一学期',
                value: 1
            }, {
                label: '第二学期',
                value: 2
            }],
            school_year:'',
            school_year_list:[],
            file_list: [],
            upload_show: false,
        }
    },
    created() {
        this.$axios.get('personnel/department/all/?')
            .then(res => {
                res.results.forEach(async res => { // 使用async关键字
                    let result = []; // 用于存储请求结果
                    await this.$axios({
                        method: 'get',
                        url: `class/class/all/?department=${res.id}`,
                        headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`},
                    }).then(response => {
                        response.results.forEach(item => {
                            result.push({
                                value: item.id,
                                label: item.name
                            });
                        });
                    })
                    this.class_list.push({
                        value: res.id,
                        label: res.name,
                        children: result // 将请求结果作为children返回
                    });
                });
            })
        this.$axios.get('basic_data/school_year/')
            .then(response => {
                response.results.forEach(item => {
                    this.school_year_list.push({
                        value:item.id,
                        label:item.name
                    })
                })
            })

    },
    mounted() {
        this.fileUrl = 'http://127.0.0.1:8000/api/v1/download/course_table.xlsx'
    },
    watch: {

        multipleSelection() {
            this.change_disabled = this.multipleSelection.length !== 1;
            this.delete_disabled = this.multipleSelection.length === 0;
        }
    },
    methods: {
        close() {
            this.teach_time = ''
            this.teach_week = ''
            this.show = false
            this.course = null
            this.start_week = null
            this.end_week = null
            this.address = null
        },
        show_add(row, column, cell, event) {
            this.course_list = []
            this.address_list = []
            if (!this.semester) {
                this.$message.error('请先选择学期')
                return false
            }
            if (!this.group) {
                this.$message.error('请先选择班级')
                return false
            }
            if (!this.school_year){
                this.$message.error('请先选择学年')
                return false
            }

            this.teach_time = this.scheduleData.indexOf(row) + 1
            this.teach_week = this.days.indexOf(column.label) + 1
            this.$axios.get(`course/course/all/`)
                .then(res => {
                    res.results.forEach(async res => { // 使用async关键字
                        this.course_list.push({
                            value: res.id,
                            label: res.name
                        });
                    });
                })
            this.$axios.get('basic_data/room_manage/all/')
                .then(res => {
                    res.results.forEach(async res => { // 使用async关键字
                        this.address_list.push({
                            value: res.id,
                            label: res.name
                        });
                    });
                })

            this.show = true
        },
        submit_add() {
            this.$axios.post('course/course_table/', {
                course: this.course[0],
                group: this.group[1],
                start_week: this.start_week,
                end_week: this.end_week,
                teach_week: this.teach_week,
                teach_time: this.teach_time,
                address: this.address[0],
                semester:this.semester,
                school_year:this.school_year
            }).then(response => {
                this.$message.success('添加成功')
                this.search()
            }).catch(error => {
                this.$message.error(error)
            })
            this.close()
        },
        handleSizeChange(val) {
            this.page_size = val
            this.current_now = 1
            this.$axios({
                method: 'GET',
                url: `basic_data/course_type/?size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                })
                .catch(error => {

                })
        },
        handleCurrentChange(val) {
            this.$axios({
                method: 'get',
                url: `basic_data/course_type/?page=${val}&size=${this.page_size}`,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    this.tableData = response.results.results
                    this.total = response.results.count; // 更新总条目数
                })
                .catch(error => {

                })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        search() {
            let search = ''
            this.scheduleData = [
                {
                    time: '第一节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第二节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第三节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第四节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第五节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
                {
                    time: '第六节',
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: []
                },
            ]
            if (this.group && this.semester) {
                search += `group=${this.group[1]}&semester=${this.semester}&school_year=${this.school_year}`
            } else {
                this.$message.error('请选择班级，学期')
                return false
            }

            this.$axios({
                method: 'get',
                url: this.base_url + search,
                headers: {"Authorization": `Bearer ${this.$cookies.get('token')}`}
            })
                .then(response => {
                    response.results.forEach(item => {
                        this.scheduleData[item.teach_time - 1][this.dictionary[item.teach_week]].push(item)
                    })
                })
                .catch(error => {

                })
        },

        mutilADD () {
            this.file_list = []
            this.upload_show = !this.upload_show
        },
        fileChange(file, file_list) {
            this.file_list = file_list
        },
        submitUpload() {
            const fileType = this.file_list[0].name.substring(this.file_list[0].name.lastIndexOf('.'))
            if (fileType.toLowerCase() !== '.xlsx') {
                this.$message.error('文件必须为.xlsx类型')
                this.file_list = []
                return false
            }
            let formData = new FormData();
            this.file_list.forEach(item => {
                formData.append("file", item.raw);
            });

            this.$axios({
                method: 'post',
                url: 'course/course_table/file_update/',
                data: formData,
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(response => {
                    this.$message.success('上传成功！')
                    this.$axios({
                        method: 'get',
                        url: `${this.base_url}&size=${this.page_size}`
                    }).then(response => {
                        this.tableData = response.results.results
                        this.total = response.results.count
                    })
                }
            ).catch(error => {
                    this.$message.error('上传失败，请稍后重试')
                }
            )
            this.upload_show = false
        }
    },


}
</script>

<style scoped>
.add {
    width: 100vw;
    height: 100vh;
    background: rgba(200, 200, 200, 0.5);
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
}

.form {
    width: 400px;
    height: 280px;
    background: white;
    border-radius: 3px;
    position: absolute;
    top: calc(50vh - 140px);
    left: calc(50vw - 200px);
    padding: 10px;
}

.form div:first-child span {
    font-weight: bold;
}

.form div:first-child i {
    font-size: 18px;
    line-height: 20px;
    position: absolute;
    right: 10px;
    font-weight: bold;
    cursor: pointer;
}

.form > span {
    display: inline-block;
    width: 70px;
}

.form .el-cascader, .form .el-input {
    display: inline-block;
    width: 280px;
    margin-top: 20px;
}

.seal-search {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
}

.el-input {
    width: 200px;
    margin: 0 15px;
}

.el-select {
    margin: 0 15px;
}

.content-table {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 15px;
    padding: 20px;
    text-align: left;

}

.btn-group {
    margin-bottom: 15px;
}

.primary {
    font-size: 12px;
    background: #e7faf0;
    border: 1px solid #d0f5e0;
    color: #13ce66;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.status-close {
    font-size: 12px;
    background: #ffeded;
    border: 1px solid #ffdbdb;
    color: #ff4949;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.senior {
    font-size: 12px;
    background: #e8f4ff;
    border: 1px solid #d1e9ff;
    color: #1890ff;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.intermediate {
    font-size: 12px;
    background: #fff8e6;
    border: 1px solid #fff1cc;
    color: #ffba00;
    border-radius: 5px;
    width: 60%;
    margin: 0 auto;
}

.pagination {
    text-align: right;
    padding-top: 20px;
}

.operation-btn-group .el-button {
    height: 20px;
    font-size: 12px;
    padding: 0;
}
.upload {
    width: 100vw;
    height: 100vh;
    background: rgba(200, 200, 200, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.upload-box {
    width: 400px;
    height: 350px;
    background: white;
    margin-top: calc(50vh - 175px);
    margin-left: calc(50vw - 200px);
    position: relative;

}

.upload-box > .close {

    font-size: 20px;
    line-height: 30px;
    width: 100%;
    text-align: right;
    height: 30px;
    margin-bottom: 30px;

}

.upload-box > .close > i {
    margin-right: 15px;
    margin-top: 10px;
    cursor: pointer;
}

.upload-box .el-button {
    position: absolute;
    bottom: 10px;
    right: 24px;
}

.download-btn {
    margin: 0 10px;
}

</style>