<template>
    <div class="navigationBar">
        <el-aside :width="isCollapse ? '64px' : '200px'">
            <el-menu
                default-active="0"
                class="el-menu-vertical-demo"
                background-color="rgb(48, 65, 86)"
                text-color="#fff"
                :collapse="isCollapse"
                :collapse-transition="false"
                :unique-opened="true"
                active-text-color="#ffd04b"
            >
                <el-menu-item class="title" index="uniqueIndex">
                    <img src="@/assets/img/logo.png" alt="" class="small-login" v-if="isCollapse === true">
                    <img src="@/assets/img/word.png" alt="/" class="logo" slot="title">
                </el-menu-item>
                <router-link to="/home">
                    <el-menu-item index="9" @click="handleMenuItemClick('首页','/home')">
                        <i class="el-icon-setting"></i>
                        <span slot="title">首&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;页</span>
                    </el-menu-item>
                </router-link>
                <router-link to="/work_space">
                    <el-menu-item index="10" v-if="![7].includes(role)" @click="handleMenuItemClick('工作空间','/work_space')">
                        <i class="el-icon-close"></i>
                        <span slot="title">工作空间</span>
                    </el-menu-item>
                </router-link>
                <el-submenu index="4" v-if="![2].includes(role)">
                    <template slot="title">
                        <i class="el-icon-user-solid"></i>
                        <span slot="title">申&nbsp;&nbsp;&nbsp;&nbsp;请</span>
                    </template>

                    <router-link to="leave">
                        <el-menu-item index="4-1" @click="handleMenuItemClick('请假申请','/leave')">
                            <i class="el-icon-user"></i>
                            <span slot="title">请假申请</span>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <el-submenu index="1" v-if="[2,3].includes(role)">
                    <template slot="title">
                        <i class="el-icon-setting"></i>
                        <span slot="title">基础数据</span>
                    </template>
                    <router-link to="position">
                        <el-menu-item index="1-1" @click="handleMenuItemClick('岗位职称','/position')"
                                      v-if="[2,3].includes(role)">
                            <i class="el-icon-setting"></i>
                            <span slot="title">岗位职称</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="department">
                        <el-menu-item index="1-2" @click="handleMenuItemClick('院系架构','/department')"
                                      v-if="[2,3].includes(role)">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">院系架构</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="major">
                        <el-menu-item index="1-3" @click="handleMenuItemClick('专业架构','/major')"
                                      v-if="[1,2,3].includes(role)">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">专业架构</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="course_type">
                        <el-menu-item index="1-4" @click="handleMenuItemClick('课程类型','/course_type')"
                                      v-if="[1,2,3].includes(role)">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">课程类型</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="class_room">
                        <el-menu-item index="1-5" @click="handleMenuItemClick('课程类型','/class_room')"
                                      v-if="[1,2,3].includes(role)">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">教室管理</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="evaluation_content">
                        <el-menu-item index="1-6" @click="handleMenuItemClick('评教内容','/class_room')"
                                      v-if="[1,2,3].includes(role)">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">评教内容</span>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <el-submenu index="2" v-if="[1,2,3].includes(role)">
                    <template slot="title">
                        <i class="el-icon-user-solid"></i>
                        <span slot="title">人力资源</span>
                    </template>

                    <router-link to="teacher">
                        <el-menu-item index="2-1" @click="handleMenuItemClick('教职工管理','/teacher')"
                                      v-if="[1,2,3,4].includes(role)">
                            <i class="el-icon-user"></i>
                            <span slot="title">教职工管理</span>
                        </el-menu-item>
                    </router-link>

                    <router-link to="resignation">
                        <el-menu-item index="2-3" @click="handleMenuItemClick('离职档案','/resignation')"
                                      v-if="[1,2,3,4].includes(role)">
                            <i class="el-icon-document"></i>
                            <span slot="title">离职档案</span>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <el-submenu index="3" v-if="[1,2,3,4,5].includes(role)">
                    <template slot="title">
                        <i class="iconfont icon-banji" style="margin-right: 8px;margin-left: 3px;"></i>
                        <span slot="title">班级管理</span>
                    </template>
                    <router-link to="class">
                        <el-menu-item index="3-1" @click="handleMenuItemClick('班级管理','/class')">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">班级管理</span>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <el-submenu index="5">
                    <template slot="title">
                        <i class="iconfont icon-banji" style="margin-right: 8px;margin-left: 3px;"></i>
                        <span slot="title">课程管理</span>
                    </template>
                    <router-link to="course">
                        <el-menu-item index="5-1" v-if="[1,2,3].includes(role)" @click="handleMenuItemClick('课程管理','/source')">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">课程管理</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="stop_course">
                        <el-menu-item index="5-2" v-if="[1,2,3].includes(role)" @click="handleMenuItemClick('停上课程','/stop_course')">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">停上课程</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="arrange_course">
                        <el-menu-item index="5-3" v-if="[1,2,3].includes(role)" @click="handleMenuItemClick('课程安排','/arrange_course')">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">课程安排</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="course_table">
                        <el-menu-item index="5-4" @click="handleMenuItemClick('课表查询','/course_table')">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">课表查询</span>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <el-submenu index="6" v-if="[1,2,3].includes(role)">
                    <template slot="title">
                        <i class="iconfont icon-banji" style="margin-right: 8px;margin-left: 3px;"></i>
                        <span slot="title">学生管理</span>
                    </template>
                    <router-link to="student">
                        <el-menu-item index="6-1" @click="handleMenuItemClick('在籍学生','/student')">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">在籍学生</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="graduation">
                        <el-menu-item index="6-2" @click="handleMenuItemClick('毕业学生','/graduation')">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">毕业学生</span>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <el-submenu index="7">
                    <template slot="title">
                        <i class="iconfont icon-banji" style="margin-right: 8px;margin-left: 3px;"></i>
                        <span slot="title">成绩管理</span>
                    </template>
                    <router-link to="enter_score">
                        <el-menu-item index="7-1" v-if="![7].includes(role)" @click="handleMenuItemClick('成绩录入','/enter_score')">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">成绩录入</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="search_score">
                        <el-menu-item index="7-2" @click="handleMenuItemClick('成绩查询','/search_score')">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">成绩查询</span>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <el-submenu index="8" v-if="[7].includes(role)">
                    <template slot="title">
                        <i class="iconfont icon-banji" style="margin-right: 8px;margin-left: 3px;"></i>
                        <span slot="title">学生评教</span>
                    </template>
                    <router-link to="evaluation_teacher">
                        <el-menu-item index="8-1" v-if="[7].includes(role)" @click="handleMenuItemClick('学生评教','/evaluation_teacher')">
                            <i class="el-icon-cpu"></i>
                            <span slot="title">学生评教</span>
                        </el-menu-item>
                    </router-link>
                </el-submenu>

            </el-menu>
        </el-aside>
    </div>
</template>


<script>
import {bus} from '../main'

export default {
    name: 'AsideView',
    data() {
        return {
            icon: 'el-icon-s-fold',
            inputVisible: false,
            inputValue: '',
            isCollapse: this.$global.isCollapse,
            role: Number(this.$cookies.get('role'))
        }
    },
    mounted() {
        bus.$on('isCollapseChanged', this.handleIsCollapseChanged)
        bus.$on('isPermissionChanged', this.handleIsPermissionChanged)
    },
    methods: {
        handleIsPermissionChanged() {
            this.role = Number(this.$cookies.get('role'))
        },
        handleIsCollapseChanged(newValue) {
            this.isCollapse = newValue
        },
        handleMenuItemClick(word, url) {
            const existingIndex = this.$global.choice_list.findIndex(item => item.includes(word));

            if (existingIndex === -1) {
                if (this.$global.choice_list.length >14) {
                    this.$alert('新打开了太多的选项卡，请先关闭一些再操作吧', '标题名称', {
                        confirmButtonText: '确定',
                        type:'warning',
                        callback: action => {
                        }
                    });
                    return
                }
                const newItem = [word, url];
                this.$global.choice_list.push(newItem);
                this.$global.choice = this.$global.choice_list.length - 1;
                bus.$emit('choiceChanged', this.$global.choice_list.length - 1);
                bus.$emit('choiceListChanged', this.$global.choice_list)
            } else {
                this.$global.choice = existingIndex;
                bus.$emit('choiceChanged', existingIndex);
            }
        }
    }
}
</script>

<style scoped>

.navigationBar {
    height: 100vh;
}

.el-aside {
    border: none;
}

.el-menu {
    height: 100vh;
    overflow: auto;
    border-right-width: 0;
}

.el-menu::-webkit-scrollbar {
    width: 0;
}


.el-menu-item span {
    user-select: none;
}

.title {
    pointer-events: none;
    font-size: 16px;
    font-weight: bold;
    padding-left: 0;
}

.content {
    width: 96%;
    margin: 20px;
}

.el-submenu {
    text-align: left;
}

.el-menu-item {
    text-align: left;
}

.logo {
    width: 160px;
}

.small-login {
    width: 40px;
    margin-left: -8px;
}

</style>