<script>
let isCollapse = false
let choice_list = [['首页','/home']]
let choice = 0
let socket = null
export default {
    isCollapse,
    choice_list,
    choice,
    socket
}
</script>

<template>

</template>

<style scoped>

</style>